import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { IonicModule } from "@ionic/angular";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { TransferTaskComponent } from "./transfer-task.component";
import { SelectUserModule } from "../select-user/select-user.module";
import { ModalHeaderComponentModule } from "../modal-header/modal-header.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    SelectUserModule,
    ModalHeaderComponentModule,
  ],
  declarations: [TransferTaskComponent],
  exports: [TransferTaskComponent],
})
export class TransferTaskModule {}
