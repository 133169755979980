import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AppVersionService } from '../../../_services/app-version.service';

@Component({
  selector: 'app-update-overlay',
  templateUrl: './update-overlay.component.html',
  styleUrls: ['./update-overlay.component.scss'],
})
export class UpdateOverlayComponent implements OnInit {

  isRequired: boolean = false;
  urls = {
    android: "https://play.google.com/store/apps/details?id=it.spacetech.it.mybuilding",
    ios: "itms://apps.apple.com/pl/app/id1525713450"
  }
  isIOS: boolean = false;

  constructor(
    private platform: Platform,
    private appVersionService: AppVersionService,
  ) { }

  ngOnInit() {
    this.platform.ready().then(() => {
      if (this.platform.is("cordova")) {
        this.appVersionService.isUpdateRequired().then((isRequired: boolean) => {
          this.isRequired = isRequired;
        });

        if(this.platform.is("ios")) {
          this.isIOS = true;
        }
      }
    });
  }

  /**
   * On update click
   */
  async onUpdate() {
    const url = this.getUrl();
    window.open(url, '_system');
  }

  getUrl() {
    if (this.platform.is("android")) {
      return this.urls.android
    }
    // Not used because of privacy policy in Safari 
    // if (this.platform.is("ios")) {
    //   return this.urls.ios;
    // }
  }
}
