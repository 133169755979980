import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { DictionaryTypes } from ".";
import { LoaderService } from "../_services/loader.service";
import { Dictionary, DictionaryValues } from "./dictionary.model";

const url = environment.api;

@Injectable({
  providedIn: "root",
})
export class DictionaryService {
  constructor(private http: HttpClient, private loaderService: LoaderService) { }

  public dictionary: Dictionary[] = [];

  public dictionaryValues: DictionaryTypes = {};

  public reportTaskStatusValues: DictionaryValues[];
  public reportSystemValues: DictionaryValues[];
  public reportPriorityValues: DictionaryValues[];
  public reportStatusValues: DictionaryValues[];
  public reportTypeValues: DictionaryValues[];
  public marketGroupCompanyValues: DictionaryValues[];
  public dictionaryMarketGroups: Dictionary = new Dictionary();
  public dictionaryMarketOrderStatus: DictionaryValues[];
  public dictionaryUserStatus: DictionaryValues[];
  public dictionaryReservationRoom: DictionaryValues[];
  public invitationConfirmationType: DictionaryValues[];
  public parkingSubtype: DictionaryValues[];
  public bookingStatusValues: DictionaryValues[];
  public companyAccessTypes: DictionaryValues[]

  async refreshDictionaryMarketGroup(
    providerId: number,
    realodDictionary = false
  ) {
    if (!this.dictionary || realodDictionary) {
      await this.refreshDictionary(true);
    }

    this.dictionaryMarketGroups = this.dictionary.find(
      (a) => a.idObject === providerId && a.table === "company"
    );
  }

  createDictionary(dictionary) {
    return this.http.post(url + "dictionary", dictionary);
  }

  createDictionaryValue(idDictionary: number, keyValue: string) {
    return this.http.post(url + "dictionary/" + idDictionary + "/value", {
      key: keyValue,
    });
  }

  editDictionaryValue(
    idDictionary: number,
    id: number,
    keyValue: DictionaryValues
  ) {
    return this.http.put(url + "dictionary/" + idDictionary + "/value/" + id, {
      id,
      key: keyValue.key,
    });
  }

  deleteDictionaryValue(idDictionary: number, id: number) {
    return this.http.delete(
      url + "dictionary/" + idDictionary + "/value/" + id
    );
  }

  getDictionaryAll() {
    return this.http.get<Dictionary[]>(url + "dictionary");
  }

  getDictionarySync() {
    return this.http.get<Dictionary[]>(url + "dictionary")
      .toPromise()
      .then((dictionary: Dictionary[]) => {
        this.dictionary = dictionary;
      });
  }

  async refreshDictionary(reload: boolean = false) {
    if (this.dictionary.length === 0 || reload) {
      await this.getDictionarySync();
    }
    const dictionaryMap: { variable: string, name: string; }[] = [
      { variable: 'reportTaskStatusValues', name: 'report.task.status' },
      { variable: 'dictionaryMarketOrderStatus', name: 'market.order.status' },
      { variable: 'reportSystemValues', name: 'report.system' },
      { variable: 'reportStatusValues', name: 'report.status' },
      { variable: 'reportTypeValues', name: 'report.type' },
      { variable: 'reportPriorityValues', name: 'report.priority' },
      { variable: 'dictionaryUserStatus', name: 'user.status' },
      { variable: 'dictionaryReservationRoom', name: 'reservation.room' },
      { variable: 'invitationConfirmationType', name: 'company.invitation_confirmation_type' },
      { variable: 'parkingSubtype', name: 'parking.subtype' },
      { variable: 'bookingStatusValues', name: 'booking.status' },
      { variable: 'companyAccessTypes', name: 'company_access.type'}
    ]

    if (this.dictionary.length > 0) {
      dictionaryMap.forEach(({variable, name}) => {
        this.dictionaryValues[name] = this[variable] = this.dictionary.find(
          (v) => v.name === name
        )?.dictionaryValues;
      });
    }
  }

  getDictionaryValue(
    values: DictionaryValues[],
    value: number
  ): DictionaryValues {
    return values.find((a) => a.value === value);
  }


  getDictionaryValueColor(dict: DictionaryValues[], value: number) {
    if (!this.dictionary) {
      return;
    }

    const r = dict.find((v) => v.value === value);

    return r ? r.color : null;
  }

  systemName(value: number) {
    if (this.reportSystemValues) {
      const system = this.reportSystemValues.find((b) => b.value === value);
      return system ? system.key : "";
    }
  }

  valueName(dict: DictionaryValues[], value: number) {
    if (this.dictionary) {
      const name = dict.find((b) => b.value === value);
      return name ? name.key : "";
    }
  }

  getDictionaryKeyColor(dict: DictionaryValues[], key: string) {
    if (!this.dictionary) {
      return;
    }

    const r = dict.find((v) => v.key === key);

    return r ? r.color : null;
  }

  valueNameByKey(dict: DictionaryValues[], key: string) {
    if (this.dictionary) {
      const name = dict.find((b) => b.key === key);
      return name ? name.key : "";
    }
  }
}
