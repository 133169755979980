import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ThisPlatform } from "src/app/_services/platform.service";
import { TimePickerService } from "../time-picker/time-picker.service";

@Component({
  selector: "app-time-picker",
  templateUrl: "./time-picker.component.html",
  styleUrls: ["./time-picker.component.scss"],
})
export class TimePickerComponent implements OnInit {
  searchField: FormControl;

  constructor(
    public timePickerService: TimePickerService,
    public p: ThisPlatform
  ) {}

  @ViewChild("hourInput") hourInput: ElementRef;
  @ViewChild("minuteInput") minuteInput: ElementRef;

  roundMiutes = 1;

  arrayDesc: number[];
  arrayAsc: number[];

  ngOnInit() {
    this.arrayDesc =
      this.timePickerService.filterType != "timer" ? [3, 2, 1] : [2, 1];
    this.arrayAsc =
      this.timePickerService.filterType != "timer" ? [1, 2, 3] : [1, 2];

    // Metoda do zaokrąglania minut, jeżeli chcielibyśmy dać możliwość wybory np. co 5 min.
    this.timePickerService.minute =
      Math.ceil(this.timePickerService.minute / this.roundMiutes) *
      this.roundMiutes;
  }

  // SCROLLOWANIE
  onScrollMinutes(event) {
    // jeżeli scrollujemy w górę
    if (event.deltaY > 0) {
      if (this.disableMinute(this.timePickerService.minute + 1)) {
        if (this.timePickerService.hour == this.timePickerService.max[0]) {
          this.timePickerService.minute = 0;
        } else {
          this.timePickerService.minute = this.timePickerService.min[1];
        }
      } else {
        this.timePickerService.minute += 1;
      }
    }
    // jeżeli scrollujemy w dół
    else {
      if (this.disableMinute(this.timePickerService.minute - 1)) {
        if (this.timePickerService.hour == this.timePickerService.min[0]) {
          this.timePickerService.minute = 59;
        } else {
          this.timePickerService.minute = this.timePickerService.max[1];
        }
      } else {
        this.timePickerService.minute -= 1;
      }
    }
  }

  onScrollHours(event) {
    if (event.deltaY > 0) {
      if (this.disableHour(this.timePickerService.hour + 1)) {
        this.timePickerService.hour = this.timePickerService.min[0];
      } else {
        this.timePickerService.hour += 1;
      }
    } else {
      if (this.disableHour(this.timePickerService.hour - 1)) {
        this.timePickerService.hour = this.timePickerService.max[0];
      } else {
        this.timePickerService.hour -= 1;
      }
    }
    this.correctMinuteValue();
  }

  // STRZAŁKI
  hourChangeValue(value) {
    if (value > 0) {
      if (this.disableHour(this.timePickerService.hour + 1)) {
        this.timePickerService.hour = this.timePickerService.min[0];
      } else {
        this.timePickerService.hour += 1;
      }
    } else {
      if (this.disableHour(this.timePickerService.hour - 1)) {
        this.timePickerService.hour = this.timePickerService.max[0];
      } else {
        this.timePickerService.hour -= 1;
      }
    }
    this.correctMinuteValue();
  }

  minuteChangeValue(value) {
    if (value > 0) {
      if (this.disableMinute(this.timePickerService.minute + 1)) {
        if (this.timePickerService.hour == this.timePickerService.max[0]) {
          this.timePickerService.minute = 0;
        } else {
          this.timePickerService.minute = this.timePickerService.min[1];
        }
      } else {
        this.timePickerService.minute += 1;
      }
    } else {
      if (this.disableMinute(this.timePickerService.minute - 1)) {
        if (this.timePickerService.hour == this.timePickerService.min[0]) {
          this.timePickerService.minute = 59;
        } else {
          this.timePickerService.minute = this.timePickerService.max[1];
        }
      } else {
        this.timePickerService.minute -= 1;
      }
    }
  }

  /**
   * Kiedy jesteśmy na skrajnych godzinach (min i max) i minuty są poza dopuszczalnym zakresem, konieczne jest ich automatyczne ustawienie na maksymalną i minimalną
   */
  correctMinuteValue() {
    if (
      this.timePickerService.hour == this.timePickerService.max[0] &&
      this.calculateMinute(this.timePickerService.minute) >
        this.timePickerService.max[1]
    ) {
      this.timePickerService.minute = this.timePickerService.max[1];
    } else if (
      this.timePickerService.hour == this.timePickerService.min[0] &&
      this.calculateMinute(this.timePickerService.minute) <
        this.timePickerService.min[1]
    ) {
      this.timePickerService.minute = this.timePickerService.min[1];
    }
  }

  /**
   * Ogranicznik długości inputa do 2 znaków
   */
  setMinuteInput(event) {
    // blokuje ustawianie minuty, jeżeli wciskam backspace albo delete
    if (event.keyCode == 8 || event.keyCode == 46) {
      return;
    }

    let inputValue = event.target.value;

    if (inputValue.length > 2) {
      this.minuteInput.nativeElement.value = inputValue.slice(0, 2);
      this.timePickerService.minute = inputValue.slice(0, 2);
    }

    if (inputValue) {
      // Dla maksymalnej godziny ustawiam maksymalną minutę zgodnie z ograniczeniem. Minimalna minuta równa 0
      if (this.timePickerService.hour == this.timePickerService.max[0]) {
        if (parseInt(inputValue) > this.timePickerService.max[1]) {
          this.timePickerService.minute = this.timePickerService.max[1];
        } else if (parseInt(inputValue) < 0) {
          this.timePickerService.minute = 0;
        } else {
          this.timePickerService.minute = parseInt(inputValue);
        }
      }
      // Dla minimalnej godziny ustawiam minimalną minutę zgodnie z ograniczeniem. Maksymalna minuta równa 59
      else if (this.timePickerService.hour == this.timePickerService.min[0]) {
        if (parseInt(inputValue) > 59) {
          this.timePickerService.minute = 59;
        } else if (parseInt(inputValue) < this.timePickerService.min[1]) {
          this.timePickerService.minute = this.timePickerService.min[1];
        } else {
          this.timePickerService.minute = parseInt(inputValue);
        }
      }
      // Dla godzin w środku zakresu limit minut jest między 0 a 59
      else {
        if (parseInt(inputValue) > 59) {
          this.timePickerService.minute = 59;
        } else if (parseInt(inputValue) < 0) {
          this.timePickerService.minute = 0;
        } else {
          this.timePickerService.minute = parseInt(inputValue);
        }
      }
    }
  }
  /**
   * Ogranicznik długości inputa do 2 znaków
   */
  setHourInput(event) {
    // blokuje ustawianie minuty, jeżeli wciskam backspace albo delete
    if (event.keyCode == 8 || event.keyCode == 46) {
      return;
    }
    let inputValue = event.target.value;

    if (inputValue.length > 2) {
      this.hourInput.nativeElement.value = inputValue.slice(0, 2);
      this.timePickerService.hour = inputValue.slice(0, 2);
    }
    if (inputValue) {
      if (parseInt(inputValue) > this.timePickerService.max[0]) {
        this.timePickerService.hour = this.timePickerService.max[0];
      } else if (parseInt(inputValue) < this.timePickerService.min[0]) {
        this.timePickerService.hour = this.timePickerService.min[0];
      } else {
        this.timePickerService.hour = parseInt(inputValue);
      }
      this.correctMinuteValue();
    }
  }

  setMinute(minute) {
    this.timePickerService.minute = minute;
  }

  setHour(hour) {
    this.timePickerService.hour = hour;
  }

  calculateMinute(minute) {
    if (minute <= 0) {
      minute += 60 * Math.ceil(Math.abs(minute) / 60);
    } else if (minute >= 60) {
      if (Number.isInteger(minute / 60)) {
        minute -= 60 * Math.ceil(Math.abs(minute) / 60);
      } else {
        minute -= 60 * (Math.ceil(Math.abs(minute) / 60) - 1);
      }
    }
    return minute;
  }

  calculateHour(hour) {
    if (hour <= 0) {
      hour += 24 * Math.ceil(Math.abs(hour) / 24);
    } else if (hour >= 24) {
      if (Number.isInteger(hour / 24)) {
        hour -= 24 * Math.ceil(Math.abs(hour) / 24);
      } else {
        hour -= 24 * (Math.ceil(Math.abs(hour) / 24) - 1);
      }
    }
    return hour;
  }

  showMinute(value) {
    let valueInt = parseInt(value);
    return this.calculateMinute(valueInt).toString().padStart(2, "0");
  }

  showHour(value) {
    let valueInt = parseInt(value);
    return this.calculateHour(valueInt).toString().padStart(2, "0");
  }

  disableHour(value) {
    if (
      this.calculateHour(value) >= this.timePickerService.min[0] &&
      this.calculateHour(value) <= this.timePickerService.max[0]
    ) {
      return false;
    } else {
      return true;
    }
  }

  disableMinute(value) {
    if (
      this.timePickerService.hour > this.timePickerService.max[0] &&
      this.timePickerService.hour < this.timePickerService.min[0]
    ) {
      return true;
    } else if (this.timePickerService.hour == this.timePickerService.max[0]) {
      if (this.calculateMinute(value) > this.timePickerService.max[1]) {
        return true;
      } else {
        return false;
      }
    } else if (this.timePickerService.hour == this.timePickerService.min[0]) {
      if (this.calculateMinute(value) < this.timePickerService.min[1]) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
