import {
  AlertController,
  PopoverController,
  ToastController,
} from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { PopoverComponent } from "../app/_component/popover/popover.component";
import {
  PopoverButton,
  PopoverEquipment,
  PopoverLegend,
} from "../app/_component/popover/popover.model";

export class globalHelper {
  public static async Alert(
    alertController: AlertController,
    translateService: TranslateService,
    customMessage?: string,
    customConfirmButton?: string,
    customCancelButton?: string,
    backdropDissmis: boolean = true
  ): Promise<Boolean> {
    let resolveFunction: (confirm: boolean) => void;
    const promise = new Promise<boolean>((resolve) => {
      resolveFunction = resolve;
    });

    const alert = await alertController.create({
      header: translateService.instant("CONFIRM"),
      message: customMessage
        ? translateService.instant(customMessage)
        : translateService.instant("QUESTION_DELETE"),
      buttons: [
        {
          text: customCancelButton
            ? translateService.instant(customCancelButton)
            : translateService.instant("CANCEL"),
          cssClass: "secondary",
          handler: () => {
            resolveFunction(false);
          },
        },
        {
          text: customConfirmButton
            ? translateService.instant(customConfirmButton)
            : "Ok",
          handler: () => {
            resolveFunction(true);
          },
        },
      ],
      backdropDismiss: backdropDissmis,
    });
    await alert.present();
    return promise;
  }

  public static async PresentToast(
    toastController: ToastController,
    translateService: TranslateService,
    translationKey?: string,
    duration: number = 1000
  ) {
    const toast = await toastController.create({
      message: translateService.instant(
        translationKey ? translationKey : "SAVED"
      ),
      duration: duration,
      buttons: [
        {
          side: "end",
          icon: "close-circle-outline",
          role: "cancel",
        },
      ],
    });
    toast.present();
  }

  public static async Popover(
    popoverController: PopoverController,
    ev: Event,
    buttonList?: PopoverButton[],
    cssClass: string = "community__popover",
    customText?: string,
    legendList?: PopoverLegend[],
    equipmentList?: PopoverEquipment[]
  ) {
    let resolveFunction: (confirm: any) => void;
    const promise = new Promise<any>((resolve) => {
      resolveFunction = resolve;
    });

    const popover = await popoverController.create({
      component: PopoverComponent,
      cssClass: cssClass,
      event: ev,
      translucent: true,
      componentProps: {
        buttonList: buttonList,
        customText: customText,
        legendList: legendList,
        equipmentList: equipmentList,
      },
    });
    popover.present();
    popover.onDidDismiss().then((data) => {
      resolveFunction(data);
    });
    return promise;
  }

  public static scrollToFirstInvalidControl(el) {
    if (el.nativeElement.querySelector("form .ng-invalid input")) {
      el.nativeElement.querySelector("form .ng-invalid input").focus();
    } else {
      setTimeout(() => {
        el.nativeElement.querySelector(".ion-color-danger").scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 10);
    }
  }
}
