<div *ngIf="isNull(collection)" class="container no-result">
  <img src="./assets/icons/no-result.svg" />
  <p class="primary" *ngIf="!text">
    {{ "NO_RESULT" | translate }}
  </p>
  <p class="primary" *ngIf="text">
    {{ text | translate }}
  </p>
  <div *appHasRole="[permissionAdd]">
    <ion-button
      *ngIf="addLink"
      (click)="onReroute(addLink)"
      class="menu-header-button"
    >
      <ion-icon name="add-outline" slot="start"> </ion-icon>
      <ion-label> {{ addButtonText | translate }} </ion-label>
    </ion-button>
  </div>
</div>
