<div class="updateOverlay" *ngIf="isRequired">
  <ion-card class="container">
    <ion-card-header>
      <ion-card-title>{{ "UPDATE.HEADER" | translate }}</ion-card-title>
    </ion-card-header>

    <ion-card-content>
      {{ "UPDATE.DESCRIPTION" | translate }}
    </ion-card-content>
    <div class="buttons" *ngIf="!isIOS">
      <ion-button color="primary" (click)="onUpdate()">{{
        "UPDATE.UPDATE_BTN" | translate
      }}</ion-button>
    </div>
    <div class="buttons" *ngIf="isIOS">
      <ion-button class=" buttons--padding" color="primary">
        <a href="itms://apps.apple.com/pl/app/id1525713450" 
          target="_system" 
          class="buttons__link"
        >{{ "UPDATE.UPDATE_BTN" | translate }}
        </a>
      </ion-button>
    </div>
  </ion-card>
</div>
