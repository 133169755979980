<!-- <ion-grid > -->
<div [class]="gridClass">
  <div class="sb-container">
    <div class="display-flex-important">
      <ng-container *ngIf="!p.renderMobile">
        <ion-button
          *ngIf="permissionEdit && addLink"
          routerLink="{{ addLink }}"
          class="sb-add-button menu-header-button"
          size="medium"
          (onClick)="onAddClick()"
        >
          <ion-icon [name]="buttonAddIcon" slot="start"></ion-icon>
          <ion-label>{{ buttonAddLabel }}</ion-label>
        </ion-button>

        <ion-button
          *ngIf="permissionEdit && onAddClickEvent"
          class="sb-add-button menu-header-button"
          size="medium"
          (click)="onAddClick()"
        >
          <ion-icon [name]="buttonAddIcon" slot="start"></ion-icon>
          <ion-label>{{ buttonAddLabel }}</ion-label>
        </ion-button>
        <ng-container *ngFor="let button of buttons">
          <ng-container *ngIf="button.permissionEdit">
            <ion-button
              class="menu-header-button"
              *ngIf="button.hideOnMobile ? !p.renderMobile : 'true'"
              [class]="'sb-button ' + button.class"
              size="normal"
              color="light"
              (click)="button.click()"
            >
              <ion-icon
                [slot]="button.titleTranslation ? 'start' : 'icon-only'"
                *ngIf="button.iconName"
                color="secondary"
                [name]="button.iconName"
              ></ion-icon>

              <ion-label class="scan-qr-text-view">{{
                button.titleTranslation | translate
              }}</ion-label>
            </ion-button>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>

    <div 
      class="sb-container" 
      [class]="containerWidthClass"
    >
      <div 
        [class]="p.renderMobile ? 'sb-left-mobile' : 'sb-left'"
        [ngClass]="containerWidthClass">
        <ion-input
          #searchInput
          class="search"
          (ionInput)="search($event.target.value)"
          [(ngModel)]="ngModel"
          clearInput
        >
        </ion-input>
      </div>
    </div>
  </div>
</div>
