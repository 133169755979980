import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

export const Animations = {
  animeHeight: trigger("toggleHeight", [
    state(
      "inactive",
      style({
        height: "0",
        opacity: "0",
        overflow: "hidden",
      })
    ),
    state(
      "active",
      style({
        height: "*",
        opacity: "1",
        overflow: "visible",
      })
    ),
    transition("inactive => active", animate("300ms ease-in")),
    transition("active => inactive", animate("300ms ease-out")),
  ]),
  animeRotate: trigger("toggleRotate", [
    state(
      "inactive",
      style({
        transform: "rotate(0)",
      })
    ),
    state(
      "active",
      style({
        transform: "rotate(180deg)",
      })
    ),
    transition("inactive => active", animate("300ms ease-in")),
    transition("active => inactive", animate("300ms ease-out")),
  ]),
};
