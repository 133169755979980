import { AuthenticationService } from "./../_services/authentication.service";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { Modules } from "../_helpers/module";
import { MenuController } from "@ionic/angular";
import { AuthService } from "../public/auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard  {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private authService: AuthService,
    private menu: MenuController
  ) {}

  currentModule: any;

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const currentUser = this.authenticationService.currentUserValue;

    
    if (!currentUser) {
      this.menu.enable(false);
      this.authService.initialHref = state.url;
      this.router.navigate(["/auth"]);
      return false;
    }

    this.currentModule = Modules.filter((p) => p.url === state.url)[0];

    if (!this.currentModule) {
      return true;
    } else if (currentUser && this.currentModule.module <= 0) {
      return true;
    } else if (
      currentUser &&
      currentUser.modules.filter(
        (f) => f.idModule === this.currentModule.module
      ).length === 1
    ) {
      return true;
    }

    this.menu.enable(false);
    this.authenticationService.logout();

    this.router.navigate(["/auth"]);
    return false;
  }
}
