export const environment = {
  production: true,
  api: 'https://api.my-building.net/',
  tpay: 'https://secure.tpay.com/api/gw/',
  onesignal: {
    appId: '17689474-29e9-47c3-b490-bfa5529ef541',
    googleProjectNumber: '571514758069'
  },
  version: '2.92.8',
};
