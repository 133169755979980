import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { Camera } from '@ionic-native/camera/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UpdateOverlayComponent } from './app/_component/update-overlay/update-overlay.component';
import { HeaderModule } from './_component/header/header.module';
import { SharedDirectivesModule } from './_directives/shared-directives.module';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { registerLocaleData } from '@angular/common';
import '@angular/common/locales/global/pl';
import localePl from '@angular/common/locales/pl';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Network } from '@ionic-native/network/ngx';
import { mdTransitionAnimation } from '@ionic/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TimePickerComponentModule } from './app/_component/time-picker/time-picker.module';
import { GlobalErrorHandler } from './_services/global-error-handler.service';
import { NoInternetConnectionComponentModule } from './_component/no-internet-connection/no-internet-connection.module';
import { CookieService } from 'ngx-cookie-service';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { CategoryModalPageModule } from './app/market/category-modal/category-modal.module';
import { TransferTaskModule } from './app/_component/transfer-task/transfer-task.module';
import { LoadingStiModule } from './_component/loading-sti/loading-sti.module';
import { LoaderService } from './_services/loader.service';
import { EditBookingComponentModule } from './app/booking/edit-booking/edit-booking.module';
import { SelectModalMultipleUserModule } from './app/_component/select-modal-multiple-user/select-modal-multiple-user.module';
import { SelectModalMultipleUserWithAvatarModule } from './app/_component/select-modal-multiple-user-with-avatar/select-modal-multiple-user-with-avatar.module';

registerLocaleData(localePl);

export function LanguageLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/lang/', '.json');
}

const token = JSON.parse(localStorage.getItem('currentUser'))?.token;
const url = environment.api;
const config: SocketIoConfig = {
  url: url,
  options: {
    query: {
      auth: token ? `${token}` : undefined, // TODO implement token from local storage and check if token exists.
    },
  },
};

@NgModule({
  declarations: [AppComponent, UpdateOverlayComponent],
  imports: [
    NoInternetConnectionComponentModule,
    SharedDirectivesModule,
    CommonModule,
    BrowserModule,
    IonicModule.forRoot({
      backButtonText: '', // Set an empty string to have no text next to the back icon
      navAnimation: mdTransitionAnimation,
      scrollAssist: false,
      scrollPadding: false,
      innerHTMLTemplatesEnabled: true,
    }),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: LanguageLoader,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    CategoryModalPageModule,
    HeaderModule,
    NgbModule,
    TransferTaskModule,
    SocketIoModule.forRoot(config),
    LoadingStiModule,
    EditorModule,
    TimePickerComponentModule,
    EditBookingComponentModule,
    SelectModalMultipleUserModule,
    SelectModalMultipleUserWithAvatarModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Camera,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    Network,
    LoaderService,
    DatePipe,
    DecimalPipe,
    CookieService,
    AppVersion,
    Device,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
