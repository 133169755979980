import { DictionariesNames } from ".";

export class Dictionary {
    id: number;
    name: string;
    table: string;
    idObject: number;
    dictionaryValues: DictionaryValues[] = [];
}

export class DictionaryValues {
    id: number;
    key: string;
    value: number;
    order: number;
    color: string;
}


export type DictionaryTypes = {
    [key in DictionariesNames]?: DictionaryValues[]
}