import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { CategoryModalPageRoutingModule } from "./category-modal-routing.module";

import { CategoryModalPage } from "./category-modal.page";
import { TranslateModule } from "@ngx-translate/core";
import { ModalHeaderComponentModule } from "src/app/app/_component/modal-header/modal-header.module";
import { InputsModule } from "src/app/_component/inputs/inputs.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CategoryModalPageRoutingModule,
    TranslateModule,
    ModalHeaderComponentModule,
    InputsModule,
    ReactiveFormsModule,
  ],
  declarations: [CategoryModalPage],
})
export class CategoryModalPageModule {}
