import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
} from "@angular/forms";
import { DateTimeAdapter } from "@danielmoncada/angular-datetime-picker";
import { TranslateService } from "@ngx-translate/core";
import { format } from "date-fns";
import { timePickerCustomTheme } from "src/app/_helpers/timePickerTheme";
import { UserService } from "src/app/_services";
import { ThisPlatform } from "src/app/_services/platform.service";
import { TimePickerComponent } from "../time-picker/time-picker.component";
import { TimePickerService } from "../time-picker/time-picker.service";

@Component({
  selector: "app-date-time-picker",
  templateUrl: "./date-time-picker.component.html",
  styleUrls: ["./date-time-picker.component.scss"],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class DateTimePickerComponent implements OnInit {
  // "calendar" | "time"
  @ViewChild("dateTimePickerTimeSet", { read: ElementRef })
  mobileDateTimePickerTimeSet: ElementRef;

  @Input()
  pickerType: string = "both";

  @Input()
  timerMin: string;

  @Input()
  timerMax: string;

  @Input()
  cssClass: string;

  @Input()
  placeholder: string;

  @Input()
  min: any;

  @Input()
  max: any;

  @Input()
  value: any;

  @Input()
  disabled: boolean = false;

  modalOpened: boolean = false;

  timePickerComponent = TimePickerComponent;

  @Output()
  afterPickerClosed: EventEmitter<any> = new EventEmitter<any>();

  formGroup: FormGroup; // Pomocnicze dla Walidacji

  timePickerCustomTheme = timePickerCustomTheme;

  smallDelay: boolean = false;

  constructor(
    public translate: TranslateService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private timePickerService: TimePickerService,
    private dateTimeAdapter: DateTimeAdapter<any>,
    private appRef: ApplicationRef,
    public userService: UserService,
    public p: ThisPlatform
  ) {}

  ngOnInit() {
    this.dateTimeAdapter.setLocale(this.userService.getDefaultLanguage());

    this.initForm();
  }

  // hak konieczny do sprawdzenia daty po kliknięciu w dzień przy otwartym modalu
  @HostListener("document:click", ["$event"])
  click() {
    if (this.modalOpened) {
      setTimeout(() => {
        let day = document
          .querySelector(".owl-dt-calendar-cell-active")
          .getAttribute("aria-label")
          .split(" ")[0];

        this.setMinAndMaxHour(new Date(new Date().setDate(+day)));
      }, 10);
    }
  }

  // nasłuch eventu przycisku wstecz, który zamyka time picker przy otwarciu wyboru daty i godziny
  @HostListener("window:popstate", ["$event"])
  onPopState() {
    const timepickerBackdrop = document.querySelector(
      ".timepicker-backdrop-overlay"
    );
    !timepickerBackdrop.dispatchEvent(new MouseEvent("click"));
  }

  formatInitialTime() {
    if (this.value) {
      return format(new Date(this.value), "HH:mm");
    } else {
      return null;
    }
  }

  setDateTimeModal(time) {
    if (this.value == undefined) {
      this.value = new Date();
    }
    this.value = this.setHour(time.split(":")[0], time.split(":")[1]);

    setTimeout(() => {
      this.afterPickerClosed.emit(this.value);
    }, 30);
  }

  initForm() {
    this.formGroup = new FormGroup({
      time: new FormControl(this.formatInitialTime(), {
        updateOn: "blur",
      }),
    });
  }

  onModalOpened(event) {
    this.modalOpened = true;

    this.setMinAndMaxHour(this.value);

    if (this.value) {
      this.timePickerService.hour = new Date(this.value).getHours();
      this.timePickerService.minute = new Date(this.value).getMinutes();
    } else {
      this.timePickerService.hour = 12;
      this.timePickerService.minute = 0;
    }

    // stylizowanie datetime pickera czystym js-em
    setTimeout(() => {
      const timePickerContainer = <HTMLElement>(
        document.querySelector(".cdk-overlay-container")
      );

      const loaderContainer = <HTMLElement>(
        timePickerContainer.querySelector("owl-date-time-timer")
      );

      // STYLIZOWANIE TIMERA
      if (this.pickerType == "timer") {
        this.timePickerService.filterType = "timer";

        timePickerContainer
          .querySelector("owl-date-time-container")
          .classList.add("datetime-picker__timer");

        // change buttons color
        timePickerContainer
          .querySelectorAll(".owl-dt-control-content")[0]
          .classList.add("owl-dt-cancel-button");

        timePickerContainer
          .querySelectorAll(".owl-dt-control-content")[1]
          .classList.add("owl-dt-confirm-button");

        // change buttons text
        timePickerContainer.querySelectorAll(
          ".owl-dt-control-content"
        )[0].textContent = this.translate.instant("CANCEL");

        timePickerContainer.querySelectorAll(
          ".owl-dt-control-content"
        )[1].textContent = this.translate.instant("SET");

        let factory = this.componentFactoryResolver.resolveComponentFactory(
          this.timePickerComponent
        );
        this.appRef.bootstrap(factory, loaderContainer);

        //USTAWIANIE DESKTOPOWEGO TIMEPICKERA NAD INPUTEM JEŻELI TIMEPICKER WYSZEDŁBY POZA VIEWPOTR

        const timePicker = <HTMLElement>(
          timePickerContainer.querySelector(".cdk-overlay-pane")
        );

        if (
          window.innerHeight - event.target.getBoundingClientRect().bottom <
          283
        ) {
          timePicker.style.transformOrigin = "left top";
          timePicker.style.bottom =
            window.innerHeight -
            event.target.getBoundingClientRect().top +
            "px";
          timePicker.style.top = "initial";
        }
      }
      // STYLIZOWANIE POZOSTAŁYCH DESKTOP
      else {
        this.timePickerService.filterType = "";
        timePickerContainer
          .querySelector("owl-date-time-timer")
          .classList.add("datetime-picker__hide-time");

        // change buttons text
        timePickerContainer.querySelectorAll(
          ".owl-dt-control-content"
        )[3].textContent = this.translate.instant("CANCEL");

        timePickerContainer.querySelectorAll(
          ".owl-dt-control-content"
        )[4].textContent = this.translate.instant("SET");

        // change buttons color
        timePickerContainer
          .querySelectorAll(".owl-dt-control-content")[3]
          .classList.add("owl-dt-cancel-button");

        timePickerContainer
          .querySelectorAll(".owl-dt-control-content")[4]
          .classList.add("owl-dt-confirm-button");

        let factory = this.componentFactoryResolver.resolveComponentFactory(
          this.timePickerComponent
        );
        this.appRef.bootstrap(factory, loaderContainer);
      }
    }, 0);
  }

  dateTimePickerClosed(details) {
    this.value = details.value._d ? details.value._d : details.value;
    this.modalOpened = false;

    if (this.pickerType !== "calendar") {
      this.value = this.setHour(
        this.timePickerService.hour,
        this.timePickerService.minute
      );
    }

    this.timePickerService.min = [0, 0];
    this.timePickerService.max = [23, 59];

    if (this.p.renderMobile && this.pickerType == "both") {
      this.mobileDateTimePickerTimeSet.nativeElement.dispatchEvent(
        new Event("click")
      );
    } else {
      this.afterPickerClosed.emit(this.value);
    }
  }

  setMinAndMaxHour(value) {
    if (
      this.min &&
      new Date(value).setHours(0, 0, 0, 0) ==
        new Date(this.min).setHours(0, 0, 0, 0)
    ) {
      this.timePickerService.min = [
        new Date(this.min).getHours(),
        new Date(this.min).getMinutes(),
      ];
    } else {
      this.timePickerService.min = [0, 0];
    }

    if (
      this.max &&
      new Date(value).setHours(0, 0, 0, 0) ==
        new Date(this.max).setHours(0, 0, 0, 0)
    ) {
      this.timePickerService.max = [
        new Date(this.max).getHours(),
        new Date(this.max).getMinutes(),
      ];
    } else {
      this.timePickerService.max = [23, 59];
    }

    if (this.timerMin) {
      this.timePickerService.min = [
        +this.timerMin.split(":")[0],
        +this.timerMin.split(":")[1],
      ];
    }
    if (this.timerMax) {
      this.timePickerService.max = [
        +this.timerMax.split(":")[0],
        +this.timerMax.split(":")[1],
      ];
    }
  }

  setHour(hour, minute) {
    const date = new Date(new Date(this.value).setHours(hour, 0, 0, 0))
    const minutes = new Date(new Date(this.value).setHours(hour, minute, 0, 0))

    date.setDate(this.value.getDate());
    date.setMonth(this.value.getMonth());
    date.setFullYear(this.value.getFullYear());
    date.setMinutes(minutes.getMinutes());

    return date;
  }
}
