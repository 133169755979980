import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { TranslateModule } from "@ngx-translate/core";
import { ModalHeaderComponent } from "./modal-header.component";
import { SharedDirectivesModule } from "src/app/_directives/shared-directives.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    SharedDirectivesModule,
  ],
  declarations: [ModalHeaderComponent],
  exports: [ModalHeaderComponent],
})
export class ModalHeaderComponentModule {}
