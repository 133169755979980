import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class ThisPlatform {
  // Point of transition to the mobile page
  pageBrakepointSmallDesktop: number = 1200;
  pageBrakepointTablet: number = 768;
  pageBrakepoint: number = 992;
  pageBrakepointSmallMobile: number = 576;
  // Defines whether the platform is mobile
  isMobile: boolean;

  // TODO change to one variable!!!
  renderSmallDesktop: boolean;
  renderTablet: boolean;
  renderMobile: boolean;
  renderSmallMobile: boolean;
  
  constructor(private platform: Platform) {
    this.isMobile = this.platform.is("mobile");
    this.renderSmallDesktop = window.innerWidth < this.pageBrakepoint;
    this.renderMobile = window.innerWidth < this.pageBrakepoint;
    this.renderSmallMobile = window.innerWidth < this.pageBrakepoint;
  }

  onResize(width: number) {
    this.renderSmallDesktop = width < this.pageBrakepointSmallDesktop;
    this.renderTablet = width < this.pageBrakepointTablet;
    this.renderMobile = width < this.pageBrakepoint;
    this.renderSmallMobile = width < this.pageBrakepointSmallMobile;
  }
}
