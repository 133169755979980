import { Component } from '@angular/core';

@Component({
  selector: 'app-loading-sti',
  templateUrl: './loading-sti.component.html',
  styleUrls: ['./loading-sti.component.scss'],
})
export class LoadingStiComponent {

}
