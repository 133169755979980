<ion-content class="modal-outher-container">
  <ion-grid class="modal-inner-container">
    <ion-row class="content-padding-vertical" justify-content-center>
      <ion-col>
        <app-modal-header
          header="{{ 'MARKET.NEW_CATEGORY' | translate }}"
          (close)="back()"
        >
        </app-modal-header>

        <form [formGroup]="form">
          <div class="padding__input">
            <app-input
              type="input"
              [form]="form"
              formControlName="name"
              label="COMPANY.NAME"
            ></app-input>
          </div>
        </form>
        
        <ion-label color="danger padding__input" class="font-size__16"> {{errorMessage}} </ion-label>
        <ion-item class="input padding__input" lines="none">
          <ion-button
            class="button-margin-top-md"
            slot="end"
            margin
            (click)="Save()"
            size="medium"
            >{{'SAVE' | translate}}</ion-button
          >
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
