import { Directive, ElementRef, Input, OnInit } from "@angular/core";

@Directive({
  selector: "[scrollbar]",
})
export class ScrollbarDirective implements OnInit {
  @Input() scrollbar: string;

  hostElement: HTMLElement;

  constructor(public elementRef: ElementRef) {}

  ngOnInit() {
    this.hostElement = this.elementRef.nativeElement;
    if (
      this.hostElement &&
      this.hostElement.tagName &&
      this.hostElement.tagName == "ION-CONTENT"
    ) {
      let el = document.createElement("style");
      el.innerText = this.getCustomStyle();
      this.hostElement.shadowRoot.appendChild(el);
    }
  }

  getCustomStyle() {
    if(this.scrollbar == 'dark') {
      return `@media(pointer: fine) {::-webkit-scrollbar {width: 7px;}::-webkit-scrollbar-track {background: var(--ion-color-secondary);}::-webkit-scrollbar-track:hover {background: var(--ion-color-secondary);}::-webkit-scrollbar-thumb {background: var(--ion-color-primary);}}`;
    } else {
      return `@media(pointer: fine) {::-webkit-scrollbar {width: 7px;}::-webkit-scrollbar-track {background: #fff;}::-webkit-scrollbar-track:hover {background: #f7f7f7;}::-webkit-scrollbar-thumb {background: var(--ion-color-primary);}}`;
    }
  }
}
