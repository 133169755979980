import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { UserBasic } from 'src/app/_models/user.model';
import { UserService } from 'src/app/_services';
import { ThisPlatform } from 'src/app/_services/platform.service';

@Component({
  selector: 'app-select-modal-multiple-user-with-avatar',
  templateUrl: './select-modal-multiple-user-with-avatar.component.html',
  styleUrls: ['./select-modal-multiple-user-with-avatar.component.scss'],
})
export class SelectModalMultipleUserWithAvatarComponent implements OnInit {

  @Input()
  headerLabel: string

  @Input()
  filtrIdCompany: number;

  @Input()
  removeCurrentUserFromList: boolean = false;

  @Input() 
  currentUserList: UserBasic[];

  @Input()
  buttonLabel: string

  headerText: string;
  buttonText: string;
  toggleButtonText: string = "BOOKING.SHOW_SELECTED_PARTICIPANTS";

  usersList: UserBasic[] = [];
  usersFiltredList: UserBasic[] = [];
  selectedUsersList: UserBasic[] = [];
  
  loading: boolean = false;
  noUsersAvailable: boolean = false;

  constructor(
    public userServ: UserService,
    private modalCtrl: ModalController,
    public p: ThisPlatform,
    private translate: TranslateService
  ) { }

  async ngOnInit() {
    this.loading = true;
    this.headerText = this.translate.instant(this.headerLabel);
    this.buttonText = this.translate.instant(this.buttonLabel);

    await this.userServ.getActiveSync(true, this.filtrIdCompany)
      .finally(() => {
        this.usersList = [...this.userServ.users];
      });

    if (this.removeCurrentUserFromList) {
      this.usersList = this.usersList.filter(
        (x) => x.id !== this.userServ.userStorage.id
      );
    }
    this.usersFiltredList = [...this.usersList];

    if(this.currentUserList.length > 0) {
      this.selectedUsersList = [...this.currentUserList];
    }

    this.areUsers();

    this.loading = false;
  }


  onClickUser(user) {
    if(!this.selectedUsersList.some(el => el.id == user.id)) {
      this.selectedUsersList.push(user);
    } else {
      this.selectedUsersList = this.selectedUsersList.filter(el => el.id != user.id )
    }
  }

  save() {
    this.modalCtrl.dismiss(this.selectedUsersList);
  }

  back() {
    this.modalCtrl.dismiss(undefined, "backdrop");
  }

  /**
   * Function checking if list includes user 
   * @param user 
   * @returns boolean
   */
  checkUser(user: UserBasic) {
    if(!this.selectedUsersList.some(el => el.id == user.id)) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Check users list if is not empty
   */
  private areUsers() {
    if(this.usersFiltredList.length > 0) {
      this.noUsersAvailable = false;
    } else {
      this.noUsersAvailable = true;
    }
  }

  onChangeSearch(data) {
    if (data && data.length > 0) {
      this.usersFiltredList = this.usersList.filter((s) => {
        return (s.firstName + "" + s.lastName + "" + s.email + "").toUpperCase().includes(data.toUpperCase());
      });
    } else {
      this.usersFiltredList = this.usersList;
    }

    this.areUsers();
  }

  onShowSelected(event) {
    if(event.detail.checked) {
      this.usersFiltredList = this.selectedUsersList;
    } else {
      this.usersFiltredList = this.usersList;
    }
    this.areUsers();
  }

}
