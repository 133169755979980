<div
  *ngIf="!p.renderMobile"
  class="time-picker__container"
  [ngClass]="{
    'time-picker__container--timer': timePickerService.filterType == 'timer'
  }"
>
  <h2
    class="time-picker__header"
    *ngIf="timePickerService.filterType != 'timer'"
  >
    {{ "HOUR:" | translate }}
  </h2>
  <div class="time-picker__inner-container">
    <!-- GODZINY -->
    <div class="time-picker__column" (wheel)="onScrollHours($event)">
      <div class="time-picker__number" (click)="hourChangeValue(-1)">
        <ion-icon slot="start" name="chevron-up"></ion-icon>
      </div>
      <div
        *ngFor="let i of arrayDesc"
        class="time-picker__number"
        [ngClass]="{
          'time-picker__number--disable': disableHour(
            timePickerService.hour - i
          )
        }"
        (click)="
          disableHour(timePickerService.hour - i)
            ? ''
            : setHour(timePickerService.hour - i)
        "
      >
        {{ showHour(timePickerService.hour - i) }}
      </div>
      <input
        #hourInput
        type="number"
        [value]="showHour(timePickerService.hour)"
        class="time-picker__input"
        (keyup)="setHourInput($event)"
        max="24"
      />
      <div
        *ngFor="let i of arrayAsc"
        class="time-picker__number"
        [ngClass]="{
          'time-picker__number--disable': disableHour(
            timePickerService.hour + i
          )
        }"
        (click)="
          disableHour(timePickerService.hour + i)
            ? ''
            : setHour(timePickerService.hour + i)
        "
      >
        {{ showHour(timePickerService.hour + i) }}
      </div>
      <div class="time-picker__number" (click)="hourChangeValue(1)">
        <ion-icon slot="start" name="chevron-down"></ion-icon>
      </div>
    </div>

    <!-- SEPARATOR -->
    <div class="time-picker__separator">:</div>

    <!-- MINUTY -->
    <div class="time-picker__column" (wheel)="onScrollMinutes($event)">
      <div
        class="time-picker__number"
        (click)="minuteChangeValue(-roundMiutes)"
      >
        <ion-icon slot="start" name="chevron-up"></ion-icon>
      </div>
      <div
        *ngFor="let i of arrayDesc"
        class="time-picker__number"
        [ngClass]="{
          'time-picker__number--disable': disableMinute(
            timePickerService.minute - roundMiutes * i
          )
        }"
        (click)="setMinute(timePickerService.minute - roundMiutes * i)"
      >
        {{ showMinute(timePickerService.minute - roundMiutes * i) }}
      </div>
      <input
        #minuteInput
        type="number"
        [value]="showMinute(timePickerService.minute)"
        class="time-picker__input"
        (keyup)="setMinuteInput($event)"
        max="59"
      />

      <div
        *ngFor="let i of arrayAsc"
        class="time-picker__number"
        [ngClass]="{
          'time-picker__number--disable': disableMinute(
            timePickerService.minute + roundMiutes * i
          )
        }"
        (click)="setMinute(timePickerService.minute + roundMiutes * i)"
      >
        {{ showMinute(timePickerService.minute + roundMiutes * i) }}
      </div>
      <div class="time-picker__number" (click)="minuteChangeValue(roundMiutes)">
        <ion-icon slot="start" name="chevron-down"></ion-icon>
      </div>
    </div>

    <div class="time-picker__marker"></div>
  </div>
</div>
