export class UserSessionModel {
  bookingCalendarScale: string = "timeGridDay";
  firstRun: boolean = true;
  homeHideInvitations: boolean = false;
  homeHideProducts: boolean = false;
  homeHideCommunity: boolean = false;
  homeHideBookings: boolean = false;
  homeHideParkingAssignments: boolean = false;
  homeHideParkingBookings: boolean = false;
}
