<ion-item
  class="input"
  lines="{{ setLines }}"
  [ngClass]="
    form.get(formControlName).hasError('required') ||
    form.get(formControlName).hasError('maxlength') ||
    form.get(formControlName).hasError('minlength') ||
    form.get(formControlName).hasError('min') ||
    form.get(formControlName).hasError('maxLengthWithoutSpaces') ||
    (form.get(formControlName).hasError('pattern') &&
      form.get(formControlName).touched) ||
    errors.length > 0 ||
    submittedError
      ? 'control-display--invalid'
      : 'control-display--valid'
  "
>
  <div
    class="control-display w-100"
    [ngClass]="{
      'control-display--no-margin-top': type === inputType.checkbox
    }"
  >
    <ion-label *ngIf="label">
      {{ label | translate }}
      <span *ngIf="isRequired">*</span>
    </ion-label>
    <div [ngClass]="{ 'pos-relative': showPasswordEye }">
      <div
        *ngIf="showPasswordEye"
        [ngClass]="passwordEye ? 'password-eye-on' : 'password-eye-off'"
        (click)="passwordEyeAction()"
      ></div>
      <ion-input
        *ngIf="type != inputType.textarea && type != inputType.checkbox"
        type="{{ type }}"
        inputmode="{{ inputmode }}"
        placeholder="{{ placeholder }}"
        [value]="value"
        [disabled]="disabled"
        [readonly]="readonly"
        [ngClass]="setNgClass"
        (ionChange)="updateChanges($event.detail.value)"
        (ionBlur)="handleBlur()"
        (touchEvent)="onSelectTouched('{{formControlName}}')"
        [maxlength]="maxlength"
        [maskito]="maskitoOptions"
        [maskitoElement]="maskPredicate"
      ></ion-input>
    </div>

    <ion-textarea
      *ngIf="type === inputType.textarea"
      class="control-display--no-margin-top"
      autoGrow="{{ autoGrow }}"
      rows="{{ rows }}"
      [maxlength]="maxlength"
      [value]="value"
      [disabled]="disabled"
      [readonly]="readonly"
      (ionChange)="updateChanges($event.detail.value)"
      (ionBlur)="handleBlur()"
    ></ion-textarea>
  </div>
</ion-item>

<div *ngIf="errors.length > 0 || submittedError">
  <ion-label *ngFor="let item of errors" color="danger" class="error"
    >{{ item }}
  </ion-label>
</div>
