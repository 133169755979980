import { Component, EventEmitter, Input, Output } from "@angular/core";
import { PopoverController } from "@ionic/angular";

@Component({
  selector: "app-popover",
  templateUrl: "./popover.component.html",
  styleUrls: ["./popover.component.scss"],
})
export class PopoverComponent {
  @Input()
  buttonList;

  @Input()
  legendList;

  @Input()
  equipmentList;

  @Input()
  customText;

  @Output()
  actionRequest: EventEmitter<any> = new EventEmitter<any>();

  constructor(public popoverController: PopoverController) {}

  onClickButton(flag) {
    this.popoverController.dismiss(flag);
  }

  isSrc(name: string): boolean {
    return name.includes("/");
  }
}
