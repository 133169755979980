export class AttachmentImage {
  filePath: string;
  fileName: string;
}

export class AttachmentImageThumbnail {
  filePath: string;
  fileName: string;
  thumbnail: string;
}
