export class DateSetter {

    setDateUTC(base: Date, hour: number, minute: number): Date {
        const a = new Date(
            base.getUTCFullYear(),
            base.getUTCMonth(),
            base.getUTCDate(),
            hour,
            minute
        );
        return a;
    }
    setDate(base: Date, hour: number, minute: number): Date {
        const a = new Date(
            base.getFullYear(),
            base.getMonth(),
            base.getDate(),
            hour,
            minute
        );
        return a;
    }
    
}
