<ion-content class="modal-outher-container" >

  <ion-grid class="modal-inner-container select-user-with-avatar__modal">

    <ion-row>
      <ion-col>
        <app-modal-header 
          [header]="headerText" 
          (close)="back()"
        ></app-modal-header>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-item lines="none" class="w-100">
        <app-search-bar
          (searchEvent)="onChangeSearch($event)"
          class="select-user-with-avatar__search-container"
          containerWidthClass="w-100"
        ></app-search-bar>
      </ion-item>
      <ion-item lines="none" class="w-100">
        <ion-label>{{ toggleButtonText | translate }}</ion-label>
        <ion-toggle 
          slot="end" 
          (ionChange)="onShowSelected($event)"
        ></ion-toggle>
      </ion-item>
    </ion-row>

    <ng-container *ngIf="!loading">
      <ion-row
        *ngIf="!noUsersAvailable; else noUsers"
        class="select-user-with-avatar__container custom-scrollbar"
      >
        <ion-card
          *ngFor="let user of usersFiltredList"
          class="select-user-with-avatar__card-container"
          [ngClass]="{'select-user-with-avatar__card-container--selected': checkUser(user)}"
          (click)="onClickUser(user)"
        >
          <ion-card-header class="select-user-with-avatar__card-header">
            <ion-card-title class="select-user-with-avatar__card-title">
              <app-avatar [user]="user"></app-avatar>
            </ion-card-title>
            <ion-card-subtitle class="select-user-with-avatar__card-subtitle">
              <ion-label>
                {{ user.firstName }} {{ user.lastName }} 
                <span *ngIf="!filtrIdCompany"> | {{ user?.company?.name }} </span> 
                <br>
                {{ user.email }}
              </ion-label>
            </ion-card-subtitle>
          </ion-card-header>
        </ion-card>
      </ion-row>
    </ng-container>

    <ng-container *ngIf="loading">
      <app-loading-sti></app-loading-sti>
    </ng-container>

    <ion-row class="select-user-with-avatar__button-container">
      <ion-button (click)="save()">
        + {{ buttonText }}
        <span
          class="select-user-with-avatar__button-counter"
          *ngIf="selectedUsersList.length > 0"
        > 
          {{ "(" + selectedUsersList.length + ")" }}
        </span>
      </ion-button>
    </ion-row>

  </ion-grid>

  <ng-template #noUsers>
    <no-result 
      [collection]="usersFiltredList"
      text="EMPTY_LIST"
      class="select-user-with-avatar__no-result"
    ></no-result>
  </ng-template>

</ion-content>
