import { UserService } from './../_services/user.service';
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DateStiPipe } from './date-sti.pipe';
import { TranslateCountPipe } from "./translate-count.pipe";
import { SpaceAssignmentPipe } from './space-assignment-pipe.pipe';

@NgModule({
  declarations: [TranslateCountPipe, DateStiPipe, SpaceAssignmentPipe],
  imports: [CommonModule],
  exports: [TranslateCountPipe, DateStiPipe, SpaceAssignmentPipe],
  providers: [UserService]
})
export class PipesModule {}
