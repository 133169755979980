import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { error } from '../../../_helpers/log';
import { InputType } from './input.enum';
import { MaskitoOptions, MaskitoElementPredicate } from '@maskito/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputComponent),
    },
  ],
})
export class InputComponent implements OnInit, ControlValueAccessor {
  @Input()
  label?: string = '';

  @Input()
  placeholder: string;

  @Input()
  form: FormGroup;

  @Input()
  formControlName: string;

  @Input()
  disabled: boolean = false;

  @Input()
  readonly: boolean = false;

  @Input()
  value: string = '';

  @Input()
  type: string = '';

  @Input()
  inputmode?: string;

  @Input()
  setNgClass: string;

  @Input()
  autoGrow: boolean = true;

  @Input()
  rows?: string;

  @Input()
  maxlength?: number | undefined;

  @Input()
  showPasswordEye?: boolean;

  @Input()
  passwordEye?: boolean;

  @Input()
  customErrors?: {
    code: string;
    message: string;
  }[] = [];

  // Setter i getter odpowiada za prawidłową walidacje formularzy
  @Input() set submittedError(value: boolean) {
    this._submittedError = value;
    if(value) {
      this.handleBlur();
    }  
  }

  @Input()
  maskitoOptions: MaskitoOptions;

  @Output()
  changeValueEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  passwordEyeChange: EventEmitter<any> = new EventEmitter<any>();

  touched: boolean = false;
  isRequired: boolean;
  errors: any[] = [];

  inputType = InputType;

  onChange: any = () => {};
  onTouched: any = () => {};

  setLines: string = '';

  checkDisabled: string;
  checkReadonly: string;

  _submittedError: boolean; 

  readonly maskPredicate: MaskitoElementPredicate = (el) => (el as HTMLIonInputElement).getInputElement();

  updateChanges(value) {
    this.value = value;
    this.form.patchValue({
      [this.formControlName]: this.value,
    });
    if (this.touched) {
      this.validateErrors();
    }
    this.onChange(this.value);
    this.onTouched();
  }

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    if (!this.formControlName)
      error(
        'formControlName is missing, component require providing this field pointing to form field'
      );
    if (!this.form)
      error('Form is missing, component require form to bo working!!!');

    this.isRequired = this.form
      .get(this.formControlName)
      .hasValidator(Validators.required);
    // W celu wyłączenia linii dla inputa, który jest tylko do odczytu
    if (this.readonly) {
      this.setLines = 'none';
    }
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  // setDisabledState?(isDisabled: boolean): void {
  //   this.disabled = isDisabled;
  // }

  handleBlur() {
    this.touched = true;
    this.validateErrors();
  }

  validateErrors() {
    let field = this.form.get(this.formControlName);
    this.errors = [];
    for (let [key, value] of Object.entries(field.errors)) {
      switch (key) {
        case 'required':
          this.errors.push(this.translate.instant('INPUT.ERRORS.REQUIRED'));
          break;
        case 'maxlength':
          this.errors.push(
            this.translate
              .instant('INPUT.ERRORS.MAX_LENGTH')
              .replace('@0', value.requiredLength)
          );
          break;
        case 'minlength':
          this.errors.push(
            this.translate
              .instant('INPUT.ERRORS.MIN_LENGTH')
              .replace('@0', value.requiredLength)
          );
          break;
        case 'min':
          this.errors.push(
            this.translate
              .instant('INPUT.ERRORS.NEGATIVE_VALUE')
              .replace('@0', value.requiredLength)
          );
          break;
        case 'maxLengthWithoutSpaces':
          this.errors.push(
            this.translate
              .instant('INPUT.ERRORS.MAX_LENGTH')
              .replace('@0', value.requiredLength)
          );
          break;
        case 'pattern': // rozbic na funkcje sprawdzajca i zwrocenie odpowiedni klucz albo ujednolicic do jednego komunikatu
          switch (this.formControlName) {
            case 'postCode':
              this.errors.push(
                this.translate.instant('BUILDING.ZIP_CODE_VALIDATION')
              );
              break;
            case 'email':
              this.errors.push(
                this.translate.instant('ADD_COMPANY.MAIL_PATTERN_VALIDATION')
              );
              break;
            case 'mobilePhone':
            case 'telephone':
              this.errors.push(
                this.translate.instant('PHONE_PATTERN_VALIDATION')
              );
              break;
            case 'price':
              this.errors.push(this.translate.instant('MARKET.WRONG_PRICE'));
              break;
            default:
              this.errors.push(
                this.translate.instant('INPUT.ERRORS.INVALID_PATTERN')
              );
              break;
          }
        default:
          break;
      }
    }
    this.checkCustomErrors();
  }

  checkCustomErrors() {
    for (const error of this.customErrors) {
      if (this.form.get(this.formControlName).hasError(error.code))
        this.errors.push(this.translate.instant(error.message));
    }
  }

  onSelectTouched(formControlName: string): void {
    this.form.get(formControlName).markAsTouched();
  }

  passwordEyeAction() {
    this.passwordEye = !this.passwordEye;
    this.passwordEyeChange.emit(this.passwordEye);
  }

  // Setter i getter odpowiada za prawidłową walidacje formularzy
  get submittedError(): boolean {
    return this._submittedError;
  }
}
