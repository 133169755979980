import { AutoCompleteModule } from './../auto-complete/auto-complete.module';
import { NgModule } from '@angular/core';
import { SelectUserComponent } from './select-user.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SelectDropdownModule } from '../select-dropdown/select-dropdown.module';
import { INgxSelectOptions, NgxSelectModule } from 'ngx-select-ex';

const CustomSelectOptions: INgxSelectOptions = {
  optionValueField: 'id',
  optionTextField: 'text',
};

@NgModule({
  // TODO: Usunąć zbędne importy
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    RouterModule,
    SelectDropdownModule,
    AutoCompleteModule,
    ReactiveFormsModule,
    NgxSelectModule.forRoot(CustomSelectOptions),
  ],

  declarations: [SelectUserComponent],
  exports: [SelectUserComponent],
})
export class SelectUserModule {}
