import { AuthenticationService } from "./../../_services/authentication.service";
import { BehaviorSubject, Observable, of } from "rxjs";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpBackend } from "@angular/common/http";
import { UserSessionModel } from "src/app/_models/user-session.model";
import { ThisPlatform } from "src/app/_services/platform.service";
import { User } from "src/app/_models/user.model";

const url = environment.api;

@Injectable({
  providedIn: "root",
})
export class AuthService {
  // THIS SERVICE IGNORE APPLICATION INTERCEPTORS!!!

  user: User;
  initialHref: string;
  defaultUserSession: UserSessionModel = new UserSessionModel();
  emailAddressSended: string;

  public userSessionSubject: BehaviorSubject<UserSessionModel> = new BehaviorSubject<UserSessionModel>(this.defaultUserSession);

  private http: HttpClient;

  constructor(
    private handler: HttpBackend,
    private p: ThisPlatform,
    private authenticationService: AuthenticationService
  ) {
    this.http = new HttpClient(handler);
  }

  userRegister() {
    return this.http.post(url + "auth/register", this.user);
  }

  confirmRegistration(key: string) {
    return this.http.post(url + "auth/activate", { key: key });
  }

  forgetPassword(email: string, appName: string) {
    return this.http.post(url + "auth/forget-password", {
      email: email,
      appName: appName,
    });
  }

  checkResetPassword(key: string) {
    return this.http.get(url + "auth/reset-password?key=" + encodeURI(key));
  }

  resetPassword(password: string, key: string) {
    return this.http.post(url + "auth/reset-password", {
      password: password,
      key: key,
    });
  }

  onSaveLastUSerInLocalStorage(form) {
    if (form.value.rememberMe) {
      if (this.p.renderMobile) {
        localStorage.setItem(
          "lastUser",
          JSON.stringify([
            form.value.email,
            form.value.appname,
          ])
        );
      } else {
        if (form.value.email && form.value.appname) {
          localStorage.setItem(
            "lastUser",
            JSON.stringify([form.value.email, form.value.appname])
          );
        }
      }
    } else {
      localStorage.removeItem("lastUser");
    }

    if (!localStorage.getItem(form.value.email)) {
      localStorage.setItem(
        form.value.email,
        JSON.stringify(this.defaultUserSession)
      );
    }
  }

  updateUserDataInLocalStorage(userSession: UserSessionModel) {
    let userName = JSON.parse(localStorage.getItem("currentUser")).username;

    localStorage.setItem(userName, JSON.stringify(userSession));

    this.userSessionSubject.next(userSession);
    // userSession = JSON.parse(localStorage.getItem(userName));
  }

  getUserDataFromLocalStorage(): Observable<UserSessionModel> {
    let userName = JSON.parse(localStorage.getItem("currentUser")).username;

    if (!localStorage.getItem(userName)) {
      localStorage.setItem(userName, JSON.stringify(this.defaultUserSession));
    }

    let userSession: UserSessionModel = JSON.parse(
      localStorage.getItem(userName)
    );

    userSession = JSON.parse(localStorage.getItem(userName));

    this.userSessionSubject.next(userSession)
    
    return this.userSessionSubject.asObservable()
  }

  /**
   * Zmienia hasło użytkownika
   *
   * @param {FormData} formData dane z formularza, składa się z oldpassword i niepassword
   * @return {*}  {Observable<any>}
   * @memberof AuthService
   */
  changeUserPassword(data: Object): Observable<any> {
    const token = this.authenticationService.getAccessToken();
    return this.http.post(url + "auth/change-password", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
