import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectDropdownComponent } from './select-dropdown.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';




@NgModule({
  declarations: [SelectDropdownComponent],
  imports: [
    CommonModule,
    SelectDropDownModule,
    TranslateModule,
    FormsModule
  ],
  exports: [SelectDropdownComponent]
})
export class SelectDropdownModule { }
