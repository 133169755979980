import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslateConfigService {
  constructor(private translate: TranslateService) {}

  getLanguageList() {
    return [
      {
        value: 'pl',
        name: 'Polski',
      },
      {
        value: 'en',
        name: 'English',
      },
    ];
  }

  getDefaultLanguage() {
    let language = this.translate.getBrowserLang();
    this.translate.setDefaultLang(language);

    this.translate.currentLang;

    return language;
  }

  setLanguage(setLang) {
    if (setLang !== null) {
      this.translate.use(setLang);
      return setLang;
    } else {
      return this.getDefaultLanguage();
    }
  }
}
