import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HasRoleDirective } from "./has-role.directive";
import { ScrollbarDirective } from "./custom-scrollbar.directive";
import { DragAndDropDirective } from "./drag-and-drop.directive";
import { DisableLinkDirective } from "./disable-link.directive";

@NgModule({
  declarations: [HasRoleDirective, ScrollbarDirective, DisableLinkDirective],
  exports: [HasRoleDirective, ScrollbarDirective, DisableLinkDirective],
  imports: [CommonModule],
})
export class SharedDirectivesModule {}
