import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ModalHeaderComponentModule } from '../modal-header/modal-header.module';
import { SelectModalMultipleUserWithAvatarComponent } from './select-modal-multiple-user-with-avatar.component';
import { AvatarComponentModule } from '../avatar/avatar.module';
import { SearchBarModule } from '../search-bar/search-bar.module';
import { LoadingStiModule } from 'src/app/_component/loading-sti/loading-sti.module';
import { NoResultModule } from '../no-result/no-result.module';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    ModalHeaderComponentModule,
    AvatarComponentModule,
    SearchBarModule,
    LoadingStiModule,
    NoResultModule
  ],
  declarations: [SelectModalMultipleUserWithAvatarComponent],
  exports: [SelectModalMultipleUserWithAvatarComponent]
})
export class SelectModalMultipleUserWithAvatarModule { }
