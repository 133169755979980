import { HttpBackend, HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { UserModules } from "../_models/modules-request.model";
import { User } from "../_models/user.model";
import { ConfigService } from "./config.service";
import { Location } from '@angular/common';

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private configServ: ConfigService;
  private http: HttpClient;
  public logoutInProgress: boolean;
  public userModules;

  constructor(
    private handler: HttpBackend,
    private router: Router,
    private location: Location
  ) {
    this.http = new HttpClient(handler);
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(login: string, password: string, appname: string) {
    return this.http
      .post<User>(`${environment.api}auth/login`, {
        login,
        password,
        appName: appname,
        refresh: 1, // Obtain refreshToken on login
      })
      .pipe(
        map((user: User) => {
          if (user && user.token) {
            localStorage.setItem("currentUser", JSON.stringify(user));
            this.storeAccessToken(user.token);
            this.storeRefreshToken(user.refreshToken);
            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }

  logout() {
    const refreshToken = this.getRefreshToken();
    this.logoutInProgress = true;

    if (refreshToken) {
      localStorage.removeItem("currentUser");
      this.currentUserSubject.next(null);
      this.removeTokens();

      // logout from api
      this.http
        .post<any>(`${environment.api}auth/logout`, {
          refreshToken: refreshToken,
        })
        .subscribe(
          () => {
            //this.router.navigateByUrl("/auth");
            this.router.navigate(["/auth"], { replaceUrl: true });
            window.location.reload();
          },
          (err) => {}
        );
    } else {
      localStorage.removeItem("currentUser");
      this.currentUserSubject.next(null);
      //this.router.navigateByUrl("/auth");
      this.router.navigate(["/auth"], { replaceUrl: true });

      // needed for correct guest access accept when user is not logged in
      this.location.replaceState('/auth');
      window.location.reload();
    }
  }

  refreshToken() {
    let refreshToken = this.getRefreshToken();
    return this.http
      .post<any>(`${environment.api}auth/refresh`, {
        refreshToken: refreshToken,
      })
      .pipe(
        tap(({ token }) => {
          this.storeAccessToken(token);
        })
      );
  }

  getAccessToken() {
    return localStorage.getItem("TOKEN");
  }

  setNewTokens(token: string, refreshToken: string) {
    this.storeAccessToken(token);
    this.storeRefreshToken(refreshToken);
  }

  private storeAccessToken(token: string) {
    localStorage.setItem("TOKEN", token);
  }

  private storeRefreshToken(refreshToken: string) {
    localStorage.setItem("REFRESH_TOKEN", refreshToken);
  }

  private getRefreshToken() {
    return localStorage.getItem("REFRESH_TOKEN");
  }

  private removeTokens() {
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("REFRESH_TOKEN");
  }

  getUserModules(userLogged: User) {
    const token = this.getAccessToken();

    let httpParams = new HttpParams()
      .set('idUser', userLogged.id)
      .set('idApp', userLogged.idApp);
    
    return this.http.get<UserModules[]>(environment.api + "auth/modules",{
      params: httpParams,
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
  }
}
