import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnInit,
} from "@angular/core";
import { UserService, AuthenticationService } from "../_services";

@Directive({
  selector: "[appHasRole]",
})
export class HasRoleDirective implements OnInit {
  @Input("appHasRole") roles: string[];

  constructor(
    private authServ: AuthenticationService,
    private userServ: UserService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  async ngOnInit() {
    //Zmieniono na await .toPromise() - czasem trafiała pusta tablica bez listy ról usera
    await this.userServ.getPermissionSubject().toPromise();

    if (this.userServ.hasRoles(this.roles)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
