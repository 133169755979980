import { TranslateService } from "@ngx-translate/core";
import { Component, OnInit } from '@angular/core';
import { ModalController } from "@ionic/angular";
import { ThisPlatform } from "src/app/_services/platform.service";
import { NoInternetService } from "src/app/_services/no-internet.service";

@Component({
  selector: 'app-no-internet-connection',
  templateUrl: './no-internet-connection.component.html',
  styleUrls: ['./no-internet-connection.component.scss'],
})
export class NoInternetConnectionComponent implements OnInit {

  constructor(
    private translate: TranslateService,
    public modalController: ModalController,
    public p: ThisPlatform,
    private internetServ: NoInternetService
  ) { }

  ngOnInit() {}

  onClickRefreshPage(){
    this.internetServ.isOnline = window.navigator.onLine;

    if(this.internetServ.isOnline) {
      window.location.reload();
    } else {
      this.internetServ.isOnline = window.navigator.onLine;
    }
  }
}
