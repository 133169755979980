import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NoInternetService {

  isOnline: boolean = false;

  constructor(
  ) { }

}
