import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ThisPlatform } from "src/app/_services/platform.service";

@Component({
  selector: "app-modal-header",
  templateUrl: "./modal-header.component.html",
  styleUrls: ["./modal-header.component.scss"],
})
export class ModalHeaderComponent implements OnInit {
  @Input()
  header: string;

  @Input()
  alwaysDesktopButton: boolean = false;

  @Input()
  hideBackButton: boolean = false;

  @Output()
  close: EventEmitter<any> = new EventEmitter<any>();

  constructor(public p: ThisPlatform) {}

  ngOnInit() {}

  back() {
    this.close.emit();
  }
}
