import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { SharedDirectivesModule } from "src/app/_directives/shared-directives.module";
import { UserSettingsComponent } from "./user-settings.component";
import { AvatarComponentModule } from "../avatar/avatar.module";

@NgModule({
  declarations: [UserSettingsComponent],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    SharedDirectivesModule,
    AvatarComponentModule,
  ],
  exports: [UserSettingsComponent],
})
export class UserSettingsComponentModule {}
