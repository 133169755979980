<ion-content scrollbar class="modal-outher-container" >
  <div
    class="edit-booking__cover"
    *ngIf="addBookingModalOpen && p.renderSmallMobile"
  ></div>
  <ion-grid class="modal-inner-container edit-booking__container">
    <div
      class="edit-booking__cover"
      *ngIf="addBookingModalOpen && !p.renderSmallMobile"
    ></div>
    <ion-row class="overflow-auto custom-scrollbar">
      <ion-col>
        <app-modal-header
          [header]="config.header | translate"
          (close)="close()"
        >
        </app-modal-header>
        <ion-grid>
          <ion-row>
            <ion-col>
              <form [formGroup]="form">
                <app-input
                  type="input"
                  [disabled]="'true'"
                  [form]="form"
                  [maxlength]="100"
                  formControlName="name"
                  [label]="config.spaceLabel"
                ></app-input>
                <app-input *ngIf="config.topicChange"
                  type="input"
                  [disabled]="!this.editPermission"
                  [form]="form"
                  [maxlength]="100"
                  formControlName="subject"
                  label="BOOKING.TOPIC"
                ></app-input>
              </form>

              <ion-item lines="none" class="input">
                <div class="control-display">
                  <ion-label [ngClass]="{'disabled':(disableInput || (!config.dateChange))}">
                    {{ 'BOOKING.DAY' | translate }}
                  </ion-label>
                  <app-date-time-picker
                    pickerType="calendar"
                    [value]="dateStart"
                    [disabled]="disableInput || (!config.dateChange)"
                    (afterPickerClosed)="dateChange($event)"
                  ></app-date-time-picker>
                </div>
              </ion-item>

              <ion-item lines="none" class="input">
                <div class="control-display">
                  <ion-label>{{ 'BOOKING.START' | translate }}</ion-label>
                  <app-date-time-picker
                    pickerType="timer"
                    [value]="dateStart"
                    placeholder="{{ 'ADD_INVITATION.SELECT_DATE' | translate }}"
                    (afterPickerClosed)="updateBookingTime($event, 'start')"
                    [timerMin]="roomTimeFrom"
                    [timerMax]="roomTimeTo"
                    [disabled]="disableInput"
                  ></app-date-time-picker>
                </div>
              </ion-item>
              <ion-item lines="none" class="input">
                <div class="control-display">
                  <ion-label>{{ 'BOOKING.END' | translate }}</ion-label>
                  <app-date-time-picker
                    pickerType="timer"
                    [value]="dateEnd"
                    placeholder="{{ 'ADD_INVITATION.SELECT_DATE' | translate }}"
                    (afterPickerClosed)="updateBookingTime($event, 'end')"
                    [timerMin]="roomTimeFrom"
                    [timerMax]="roomTimeTo"
                    [disabled]="disableInput"
                  ></app-date-time-picker>
                </div>
              </ion-item>
              <ion-item
                *ngIf="startTimeEqualToEndTime"
                lines="none"
                class="input"
              >
                <ion-label color="danger" class="font-size__16">
                  {{ 'BOOKING.START_TIME_EQUAL_TO_END_TIME' | translate }}
                </ion-label>
              </ion-item>

              <ion-item
                *ngIf="startTimeLaterThanEndTime"
                lines="none"
                class="input"
              >
                <ion-label color="danger" class="font-size__16">
                  {{ 'BOOKING.START_LATER_THAN_END' | translate }}
                </ion-label>
              </ion-item>

              <ion-item
                *ngIf="overlappingDatesError"
                lines="none"
                class="input"
              >
                <ion-label color="danger" class="font-size__16">{{
                  'BOOKING.DATA_RANGE_VALIDATION' | translate
                }}</ion-label>
              </ion-item>

              <ion-item
                *ngIf="toShortBookingDuration"
                lines="none"
                class="input"
              >
                <ion-label color="danger" class="font-size__16">{{
                  'BOOKING.DATA_DURATION_VALIDATION' | translate
                }}</ion-label>
              </ion-item>

              <ion-item
                *ngIf="outOfAvailableTimeRange"
                lines="none"
                class="input"
              >
                <ion-label color="danger" class="font-size__16">
                  {{ 'BOOKING.OUT_OF_AVALIABLE_TIME_RANGE' | translate }}
                </ion-label>
              </ion-item>

              <ion-item
                *ngIf="dictionaryServ.bookingStatusValues?.length > 0 && config.statusDisplay"
                lines="none"
                class="input"
              >
                <div class="control-display">
                  <ion-label>
                    {{ 'BOOKING.STATUS' | translate }}
                  </ion-label>
                  <div class="edit-booking__status-border">
                    <app-status
                      [color]="
                        dictionaryServ.getDictionaryKeyColor(
                          dictionaryServ.bookingStatusValues,
                          booking.status
                        )
                      "
                      [name]="bookingServ.setStatusName(booking.status)"
                    ></app-status>
                  </div>
                </div>
              </ion-item>

              <ng-container *ngIf="config.listDisplay">
                <ion-item class="no-padding-ion-item" lines="none" (click)="toggleParticipants()">
                  <ion-label> 
                    {{ "BOOKING.INVITED_USERS" | translate }}
                    <span 
                      *ngIf="booking.participants.length>0 && 
                      hideParticipants"
                    >
                      ({{booking.participants.length}})
                    </span>
                  </ion-label>
                  <ion-icon
                    class="header-title-arrow"
                    size="large"
                    name="chevron-down-outline"
                    [@toggleRotate]="!hideParticipants ? 'active' : 'inactive'"
                  >
                  </ion-icon>
                </ion-item>
                <ion-list 
                  class="w-100 edit-booking__no-padding"
                  [@toggleHeight]="!hideParticipants ? 'active' : 'inactive'"
                >
                  <div *ngIf="booking.participants.length>0" slot="content">
                    <ion-item
                      *ngFor="let user of booking.participants"
                      lines="none"
                      class="edit-booking__user"
                    >
                      <ion-label>
                        {{ user.firstName }} {{ user.lastName }} 
                        <br>
                        ({{ user.email }})
                        </ion-label
                      >
                      <ion-button
                        *ngIf="editPermission && !disableInput"
                        (click)="onRemoveUser(user)"
                        size="small"
                        fill="clear"
                        shape="round"
                      >
                        <ion-icon slot="icon-only" name="close"></ion-icon>
                      </ion-button>
                    </ion-item>
                  </div>
                  <div *ngIf="booking.participants.length==0" slot="content">
                    <ion-item
                      lines="none"
                      class="edit-booking__user"
                    >
                      <ion-label>
                        {{ "BOOKING.NO_PARTICIPANTS" | translate }}
                      </ion-label>
                    </ion-item>
                  </div>
                </ion-list>

                <ion-item 
                  *ngIf="editPermission && !disableInput"
                  lines="none"
                >
                  <div 
                    class="control-display"
                    [ngClass]="{'edit-booking__no-margin' : hideParticipants}"
                  >
                    <ion-button
                      (click)="addUser()"
                      expand="block"
                      shape="round"
                      size="default"
                    >
                      {{
                        booking.participants.length > 0 ? 
                        ("BOOKING.EDIT_USERS" | translate) : 
                        ("BOOKING.ADD_USERS" | translate)
                      }} 
                      <ion-icon slot="end" name="add"></ion-icon>
                    </ion-button>
                  </div>
                </ion-item>
              </ng-container>
              
              <ng-container *ngIf="editPermission">
                <div
                  *ngIf="!pastBooking; else afterBookingEnded"
                  class="edit-booking__buttons-container"
                  lines="none"
                >
                  <ng-container *ngIf="config.actionButtonsDisplay">
                    <ion-button
                      *ngIf="!bookingInProgress"
                      class="button-margin-top-md margin-right-8"
                      slot="start"
                      fill="outline"
                      size="medium"
                      color="primary"
                      (click)="delete()"
                      >{{ 'BOOKING.DELETE' | translate }}</ion-button
                    >

                    <ion-button
                      *ngIf="bookingInProgress"
                      class="button-margin-top-md margin-right-0"
                      slot="start"
                      fill="solid"
                      size="medium"
                      color="secondary"
                      (click)="finish()"
                      >{{ 'BOOKING.FINISH' | translate }}</ion-button
                    >

                    <ion-button
                      *ngIf="!bookingConfirmed()"
                      [ngClass]="{ disabled: !isAllowedToConfirm() }"
                      class="button-margin-top-md margin-right-8"
                      slot="start"
                      fill="outline"
                      size="medium"
                      color="primary"
                      (click)="
                        !isAllowedToConfirm() ? onShowPopover($event) : confirm()
                      "
                      >{{ 'BOOKING.CONFIRM' | translate }}

                      <ion-icon
                        *ngIf="!isAllowedToConfirm()"
                        name="information-circle-outline"
                        class="cursor-pointer"
                      ></ion-icon>
                    </ion-button>
                  </ng-container>
                  
                  <ion-button
                    class="button-margin-top-md ion-float-right"
                    slot="end"
                    size="medium"
                    (click)="save()"
                    [disabled]="saving"
                  >
                    <ng-container *ngIf="saving">
                      {{ 'SAVING' | translate }}
                    </ng-container>
                    <ng-container *ngIf="!saving">
                      {{ 'BOOKING.SAVE' | translate }}
                    </ng-container>
                  </ion-button>
                </div>
                <ng-template #afterBookingEnded>
                  <div class="edit-booking__buttons-container">
                    <ion-button
                      class="button-margin-top-md ion-float-right"
                      slot="end"
                      size="medium"
                      (click)="save()"
                      [disabled]="saving"
                    >
                      <ng-container *ngIf="saving">
                        {{ 'SAVING' | translate }}
                      </ng-container>
                      <ng-container *ngIf="!saving">
                        {{ 'BOOKING.SAVE' | translate }}
                      </ng-container>
                    </ion-button>
                  </div>
                </ng-template>
              </ng-container>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ng-template #cancelBtn>
  <button class="timepicker-button">{{ 'CANCEL' | translate }}</button>
</ng-template>

<ng-template #DialHint>
  <p class="timepicker__dial-hint" *ngIf="!p.renderMobile">
    {{ 'TIMEPICKER_HINT' | translate }}
  </p>
</ng-template>
