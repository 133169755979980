import { Floor } from "../app/building/floor/floor.model";
import { Equipment } from "../app/equipment/equipment.model";
import { AttachmentImageThumbnail } from "./image.model";

export class SpaceModel {
  area: number;
  capacity: number;
  description: string;
  floor: Floor;
  id: number;
  idFloor: number;
  image: AttachmentImageThumbnail = new AttachmentImageThumbnail();
  name: string;
  shortName: string;
  subType: number;
  timeFrom: string;
  timeTo?: string;
  isAutomaticShare: boolean;
  forGuest: boolean;
  equipments: Equipment[];
  posX: number;
  posY: number;
  free?: boolean;
}
