import { NgxMaterialTimepickerTheme } from "ngx-material-timepicker";

export const timePickerCustomTheme: NgxMaterialTimepickerTheme = {
  container: {
    bodyBackgroundColor: "#fff",
    buttonColor: "#262E42",
  },
  dial: {
    dialBackgroundColor: "#d9d9d9",
    dialActiveColor: "#D9018D",
    dialInactiveColor: "#262E42",
    dialEditableActiveColor: "#D9018D",
    dialEditableBackgroundColor: "#262E42",
  },
  clockFace: {
    clockFaceBackgroundColor: "#d9d9d9",
    clockHandColor: "#D9018D",
    clockFaceTimeActiveColor: "#FBE9F5",
    clockFaceTimeInactiveColor: "#262E42",
    clockFaceTimeDisabledColor: "#888587",
    clockFaceInnerTimeInactiveColor: "#262E42",
  },
};
