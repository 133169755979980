export class customNgxDate {
  static stringToDateConverter(date, time: string){
    var data = new Date(date); 
    return date = new Date(
      data.getFullYear(), 
      data.getMonth(), 
      data.getDate(), 
      Number(this.getHours(time)), 
      Number(this.getMinutes(time))
    );
  }

  static getHours(time: string){
    return Number(time.substring(0,2).replace(':',''));
  }

  static getMinutes(time: string){
    return Number(time.substring(2,5).replace(':','').slice(-2));
  }
  
  static timeToString(hours:number, minutes:number){ 
    return ('0'  +  hours.toString()).substr(-2) + ':' + ('0' + minutes.toString()).substr(-2);
  }

  static getTime(date:Date){
    return this.timeToString(date.getHours(), date.getMinutes()); 
  }

  static isFutureTimeRangeCompare(startTime:string, endTime:string) {
    if (startTime && endTime) {
      if (endTime < startTime) {
        return true;
      }
      return false;
    }
  }

  static intToTime(x: string){
   return x.toString() +':00'; 
  }

  static currentTime():string{
    var currentDay = new Date();
    var currentTime = currentDay.getHours() + ':' + currentDay.getMinutes();
    return currentTime; 
  }

}