import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DebugService {

  private content: any[] = []; 

  constructor() {
    this.debug({ content: 'Debug initialized' });
  }

  debug(data: any) { 
    this.content.push(data);
  }

  contentMsg() {
    return JSON.stringify(this.content, undefined, 2);
  }

}
