import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { DateSetter } from "src/app/_helpers/dateSetter";
import { BookingModel, BookingStatus, BookingSection } from "src/app/_models/booking.model";
import { UserService } from "src/app/_services";
import { SpaceType } from "src/app/_types/space-type";
import { environment } from "src/environments/environment";
import { BookingAction } from "../_models/booking-action.enum";
import { GetBookingByUserModel } from "../_models/booking-request.model";
import { SpaceService } from "./space.service";

const url = environment.api;

@Injectable({
  providedIn: "root",
})
export class BookingService {
  dateSource: Date; // przesyła datę do add/edit booking
  dateSetter = new DateSetter();
  events = new Subject<BookingModel>();
  startTime: Date;
  endTime: Date;
  subject: string;
  booking: BookingModel;
  bookingsSubject: Subject<[]> = new Subject<[]>();
  bookingsCounterSubject: Subject<number> = new Subject<number>();
  bookingsCounterObservable: Observable<number> = this.bookingsCounterSubject.asObservable();
  type: BookingSection = BookingSection.MyBookingAll;
  calendarDate: Date;

  equipmentsCounter: number;
  bookingsCounter: number = 0;
  bookingsLoading: boolean = false;

  filterDetailsShowLoader: boolean;

  filterDetailsSubject: Subject<any> = new Subject<any>();

  constructor(
    private http: HttpClient,
    private alertCon: AlertController,
    private translateServ: TranslateService,
    private spaceServ: SpaceService,
    private userServ: UserService
  ) {}

  reloadFilter(filterDetails) {
    this.filterDetailsSubject.next(filterDetails);
  }

  getBookings(idSpace: number) {
    let httpParams = new HttpParams();

    httpParams = httpParams.set("bookingSpacesList", idSpace.toString());

    if (this.startTime) {
      httpParams = httpParams.set("startTime", this.startTime.toISOString());
    }
    if (this.endTime) {
      httpParams = httpParams.set("endTime", this.endTime.toISOString());
    }

    return this.http.get<BookingModel[]>(url + "booking", {
      params: httpParams,
    });
  }

  getBookingByUser(requestParams: GetBookingByUserModel) {
    let httpParams = new HttpParams().set(
      "spaceType",
      requestParams.spaceType.toString()
    );
    if (requestParams.idUserOwner) {
      httpParams = httpParams.set(
        "idUserOwner",
        requestParams.idUserOwner.toString()
      );
    }
    if (requestParams.order) {
      httpParams = httpParams.set("order", requestParams.order);
    }
    if (requestParams.limit) {
      httpParams = httpParams.set("limit", requestParams.limit.toString());
    }
    if (requestParams.startTimeGreatherThan) {
      httpParams = httpParams.set(
        "startTimeGreatherThan",
        requestParams.startTimeGreatherThan
      );
    }
    if (requestParams.endTimeLessThan) {
      httpParams = httpParams.set(
        "endTimeLessThan",
        requestParams.endTimeLessThan
      );
    }
    if (requestParams.endTimeGreatherThan) {
      httpParams = httpParams.set(
        "endTimeGreatherThan",
        requestParams.endTimeGreatherThan
      );
    }
    if (requestParams.startTime) {
      httpParams = httpParams.set("startTime", requestParams.startTime);
    }
    if (requestParams.endTime) {
      httpParams = httpParams.set("endTime", requestParams.endTime);
    }
    if (requestParams.idBuilding) {
      httpParams = httpParams.set(
        "idBuilding",
        requestParams.idBuilding.toString()
      );
    }
    if (requestParams.idFloor) {
      httpParams = httpParams.set("idFloor", requestParams.idFloor.toString());
    }
    if (requestParams.bookingSpaces) {
      httpParams = httpParams.set(
        "bookingSpaces[]",
        JSON.stringify(requestParams.bookingSpaces)
      );
    }
    if (requestParams.buildings) {
      httpParams = httpParams.set("buildings", requestParams.buildings);
    }
    if (requestParams.floors) {
      httpParams = httpParams.set("floors", requestParams.floors);
    }
    if(requestParams.includeParticipants) {
      httpParams = httpParams.set("includeParticipants", requestParams.includeParticipants);
    }
    if(requestParams.includeDeleted) {
      httpParams = httpParams.set("includeDeleted", requestParams.includeDeleted);
    }
    if(requestParams.idCompany) {
      httpParams = httpParams.set("idCompany", requestParams.idCompany);
    }

    return this.http.get<BookingModel[]>(url + "booking", {
      params: httpParams,
    });
  }

  getBooking(id: number) {
    return this.http.get<BookingModel>(url + "booking/" + id);
  }
  createBooking() {
    return this.http.post(url + "booking", this.booking);
  }
  updateBooking(booking: BookingModel) {
    return this.http.put(url + "booking/" + booking.id, booking);
  }
  patchBooking(booking: BookingModel, bookingAction: BookingAction) {
    return this.http.patch(url + "booking/" + booking.id, {
      action: bookingAction,
      confirm: true,
    });
  }
  deleteBooking(id: number) {
    return this.http.delete(url + "booking/" + id);
  }
  confirmBooking(id: number, bookingAction: BookingAction) {
    return this.http.patch(url + "booking/" + id, {
      action: bookingAction,
      confirm: true,
    });
  }

  reloadBooking() {
    if (this.type === BookingSection.MyBookingFuture) {
      if (this.userServ.userStorage) {
        var date = new Date(new Date().getTime());
        date.setHours(0, 0, 0, 0);

        this.getBookingByUser({
          spaceType: SpaceType.Room,
          idUserOwner: this.userServ.userStorage.id,
          order: "ASC",
          endTimeGreatherThan: date.toISOString(),
          includeParticipants: true,
        }).subscribe((bookings: any) => {
          this.bookingsSubject.next(bookings);
        });
      }
    } else if (this.type === BookingSection.MyBookingAll) {
      if (this.userServ.userStorage) {
        this.getBookingByUser({
          spaceType: SpaceType.Room,
          idUserOwner: this.userServ.userStorage.id,
          order: "DESC",
        }).subscribe((bookings: any) => {
          this.bookingsSubject.next(bookings);
        });
      }
    } else if (this.type === BookingSection.RoomBooking) {
      if (this.spaceServ.space.id > 0) {
        this.getBookings(this.spaceServ.space.id).subscribe((bookings: []) => {
          this.bookingsSubject.next(bookings);
        });
      }
    }
  }
  bookingChecker(event: BookingModel) {
    if (event.bookingSpaces.length === 0) {
      this.alerter(3);
      return false;
    }

    if (event.startTime > event.endTime) {
      this.alerter(0);
      return false;
    }

    // Sprawdza czy, spotkanie miałoby się odbyć w przeszłości
    if (
      event.startTime <
      new Date(
        Date.UTC(
          new Date().getUTCFullYear(),
          new Date().getUTCMonth(),
          new Date().getUTCDate(),
          new Date().getUTCHours(),
          new Date().getUTCMinutes()
        )
      )
    ) {
      this.alerter(1);
      return false;
    }

    // Sprawdza dostępność terminów
    let tab = [];
    this.getBookings(this.booking.id).subscribe((item: any[]) => {
      tab = item.filter((i) => {
        return (
          event.endTime > i.startTime &&
          event.startTime < i.endTime &&
          i.id !== event.id
        );
      });
      if (tab.length === 0) {
        return true;
      } else {
        this.alerter(2);
        return false;
      }
    });
  }
  async alerter(id: number) {
    let alert;
    switch (id) {
      case 0: {
        alert = await this.alertCon.create({
          header: this.translateServ.instant("BOOKING.ERROR"),
          message: this.translateServ.instant("BOOKING.BEFORE"),
          buttons: ["OK"],
        });
        break;
      }
      case 1: {
        alert = await this.alertCon.create({
          header: this.translateServ.instant("BOOKING.ERROR"),
          message: this.translateServ.instant("BOOKING.PAST"),
          buttons: ["OK"],
        });
        break;
      }
      case 2: {
        alert = await this.alertCon.create({
          header: this.translateServ.instant("BOOKING.ERROR"),
          message: this.translateServ.instant("BOOKING.CONFLICT"),
          buttons: ["OK"],
        });
        break;
      }
      case 3: {
        alert = await this.alertCon.create({
          header: this.translateServ.instant("BOOKING.ERROR"),
          message: this.translateServ.instant("BOOKING.NO_ROOM"),
          buttons: ["OK"],
        });
        break;
      }
      default: {
        break;
      }
    }
    await alert.present();
  }

  setStatusName(value: string) {
    switch(value) {
      case BookingStatus.New:
        return this.translateServ.instant("BOOKING.NEW");
      case BookingStatus.Confirmed:
        return this.translateServ.instant("BOOKING.CONFIRMED");
      case BookingStatus.Canceled:
        return this.translateServ.instant("BOOKING.CANCELED");
      case BookingStatus.Ended:
        return this.translateServ.instant("BOOKING.ENDED");
      case BookingStatus.RegistredEntry:
        return this.translateServ.instant("BOOKING.REGISTRED_ENTRY");
      case BookingStatus.RegistredDeparture:
        return this.translateServ.instant("BOOKING.REGISTRED_DEPARTURE");
      case BookingStatus.CanceledByUser:
        return this.translateServ.instant("BOOKING.CANCELED_BY_USER");
      default:
        return ' ';
    }
  }
}
