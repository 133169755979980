import { SpaceModel } from "src/app/_models/space.model";
import { CompanyBasic } from "../app/company/company.model";

export class BookingModel {
  id: number;
  idUserCreated: number;
  idUserOwner: number;
  startTime: Date;
  endTime: Date | string;
  startTimeDisplay: string; // wewnętrzne potrzeby kalendarza
  endTimeDisplay: string; // wewnętrzne potrzeby kalendarza
  title: string;
  description: string;
  allDay: false;
  bookingSpaces: BookingSpace[] = [];
  userOwner: BookingUser;
  userCreated: BookingUser;
  status: BookingStatus;
  participantsFromApp: number[] = [];
  participants: any[];
  owner: BookingOwner;
  type: BookingType;
}

export class BookingUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  telephone: string;
  idCompany: number;
  carPlate: string;
  company: CompanyBasic;
}
export class BookingSpace {
  id: number;
  idBooking: number;
  idSpace: number;
  space: SpaceModel;
  number: string;
}

export enum BookingSection {
  RoomBooking = 1,
  MyBookingAll = 2,
  MyBookingFuture = 3,
}

export enum BookingStatus {
  New = 'new',
  Confirmed = 'confirmed',
  Canceled = 'canceled',
  RegistredEntry = 'registredEntry',
  RegistredDeparture = 'registredDeparture',
  Ended = 'ended',
  CanceledByUser = 'canceledByUser',
}

export enum BookingType {
  Room = 'room',
  Parking = 'parking',
  Desk = 'desk'
}

export enum BookingOwner {
  Me = 'me',
  User = 'user',
  Guest = 'guest'
}

/**
 * @header of displaying modal
 * @spaceLabel label of the input which is displaying space name
 * @actionButtonsDisplay display cancel and cofirm buttons
 * @statusDisplay display current status of booked space
 * @listDisplay display list of attendees
 * @dateChange disable date picker
 * @topicChange disable topic input
 */
export class EditBookingConfig {
  header: string = "BOOKING.DETAILS"
  spaceLabel: string = "BOOKING.ROOM_NAME"
  actionButtonsDisplay: boolean = true;
  statusDisplay: boolean = true
  listDisplay: boolean = true;
  dateChange: boolean = true;
  topicChange: boolean = true;
}
