<ion-avatar
  slot="start"
  class="avatar"
  [ngClass]="big ? 'avatar--big' : 'avatar--small'"
  *ngIf="user"
>
  <ion-label *ngIf="!user?.avatar">
    {{ 
      user?.firstName === "SuperAdmin" ? 
      "SA" :
      ( user.firstName ? user?.firstName[0] : " " ) + 
      ( user.lastName ? user?.lastName[0] : " " )
    }}
  </ion-label>
  <img
    *ngIf="user?.avatar"
    [src]="
      user?.avatar.thumbnail ? user?.avatar.thumbnail : user?.avatar.filePath
    "
  />
</ion-avatar>
