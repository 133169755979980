import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { SpaceModel } from "../_models/space.model";
import { environment } from "src/environments/environment";
import {
  SpaceAssignmentModel,
  SpaceAssignmentSharedModel,
} from "../_models/space-assignment.model";
import { Equipment } from "../app/equipment/equipment.model";
import {
  GetAssignmentsModel,
  GetSpacesModel,
} from "../_models/space-requests.model";
import { Utils } from "../_helpers/utils";

const url = environment.api;

@Injectable({
  providedIn: "root",
})
export class SpaceService {
  space: SpaceModel = new SpaceModel();
  spaces: SpaceModel[];
  roomSubject = new Subject<number>();
  roomObserv: Observable<number> = this.roomSubject.asObservable();
  assignmentList: SpaceAssignmentModel[];

  spaceIdListAddedToInvitation: number[] = [];
  spacesAddedToInvitation: SpaceModel[] = [];

  equipmentList: Equipment[];

  spacesLoaded$ = new Subject<SpaceModel[]>();

  private spaceAssignementSubject = new Subject<void>();
  assignementsCounterSubject = new Subject<number>();
  assignementsCounterObservable: Observable<number> = this.assignementsCounterSubject.asObservable();
  spaceAssignmentChanged = this.spaceAssignementSubject.asObservable();

  assignmentsCounter: number = 0;
  assignmentsloading: boolean = false;
  spacesCounter: number;

  constructor(private http: HttpClient) {}

  searchSubtypeIcon(subtypeId) {
    let properIcon = this.spaceSubtypeIconList.filter((icon) => {
      if (icon.value == subtypeId) {
        return icon.icon;
      }
    });

    return properIcon[0].icon;
  }

  spaceSubtypeIconList = [
    {
      value: 3,
      icon: "../../../assets/icons/parking-wide.svg",
    },
    {
      value: 2,
      icon: "../../../assets/icons/parking-woman-children.svg",
    },
    {
      value: 1,
      icon: "../../../assets/icons/parking-disabled.svg",
    },
  ];

  setSpace(event) {
    this.space = event;
  }
  getSpaces(details: GetSpacesModel) {
    return this.http.get<SpaceModel[]>(url + "space", {
      params: Utils.httpParams(details),
    });
  }

  getSpace(id: number) {
    return this.http.get<SpaceModel>(url + "space/" + id);
  }
  createSpace(formData: any) {
    return this.http.post(url + "space", formData);
  }
  updateSpace(formData: any) {
    return this.http.put(url + "space/" + this.space.id, formData);
  }
  deleteSpace(id: number) {
    return this.http.delete(url + "space/" + id);
  }

  async getSpaceSync(id: number) {
    return <SpaceModel>await this.getSpace(id).toPromise();
  }

  addAssignment(spaceAssignment: SpaceAssignmentModel) {
    return this.http.post(url + "space-assignment", spaceAssignment);
  }

  addAssignmentShared(spaceAssignmentShared: SpaceAssignmentSharedModel) {
    return this.http.post(
      url + "space-assignment/shared",
      spaceAssignmentShared
    );
  }

  updateAssignment(id: number, spaceAssignment: SpaceAssignmentModel) {
    return this.http.put(url + "space-assignment/" + id, spaceAssignment);
  }

  getAssignments(details: GetAssignmentsModel) {
    return this.http.get<SpaceAssignmentModel[]>(url + "space-assignment", {
      params: Utils.httpParams(details),
    });
  }

  deleteAssignment(id: number) {
    return this.http.delete(url + "space-assignment/" + id);
  }

  getAssignment(id: number) {
    return this.http.get<SpaceAssignmentModel>(url + "space-assignment/" + id);
  }

  onAssignmentChange() {
    return this.spaceAssignementSubject.next();
  }

  addSpaceEquipment(idSpace, idEquipmentList) {
    let paramsList = "";

    if (idEquipmentList.length > 0) {
      idEquipmentList.forEach((id) => {
        paramsList += `idEquipments[]=${id.toString()}&`;
      });
    } else {
      paramsList = "idEquipments[]=";
    }

    return this.http.put(
      url + `space/${idSpace}/equipment?${paramsList.slice(0, -1)}`,
      null
    );
  }
}
