<div class="loading-container">
  <div class="loading-info" *ngIf="loadErrorState && !loadErrorState()">
    <!-- {{'LOADING2' | translate}}  -->
    Wczytywanie
  </div>

  <div
    class="loading-spinner-container"
    *ngIf="loadErrorState && !loadErrorState()"
  >
    <div class="loading-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <div class="loading-info" *ngIf="loadErrorState && loadErrorState()">
    <!-- {{'LOADING_FAIL' | translate}} -->
    Coś poszło nie tak...
  </div>

  <ion-button
    *ngIf="loadErrorState && loadErrorState()"
    class="button-margin-top-md loading-btn"
    (click)="refresh()"
    size="medium"
    >Odśwież</ion-button
  ><br />

  <ion-button
    *ngIf="
      loadErrorState && loadErrorState() && getBrowserName() == 'other'
    "
    class="button-margin-top-md loading-btn"
    size="medium"
    >Uruchom w przeglądarce</ion-button
  >
</div>
