import { CompanyBasic } from "../app/company/company.model";
import { AttachmentImage, AttachmentImageThumbnail } from "./image.model";
import { UserModules } from "./modules-request.model";
export class User {
  id: number;
  username: string;
  password: string;
  email: string;
  firstName: string;
  lastName: string;
  token: string;
  refreshToken?: string;
  mobilePhone: string;
  telephone: string;
  language: string;
  carPlate: string;
  appName: string;
  idCompany: number;
  idBuilding: number;
  idRole: number;
  idApp: number;
  status: number;
  reception: boolean;
  pushAccepted: boolean;
  firstRun: boolean;
  company: CompanyBasic = new CompanyBasic();
  visibleInVirtualReception: boolean;
  avatar: AttachmentImageThumbnail = new AttachmentImageThumbnail();
  file: string;
  role: UserRole = new UserRole();
  modules: UserModules[];
  availableModules: [{ idModule: number }];
  activated?: boolean;
}

export class UserBasic {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  telephone: string;
  idCompany: number;
  carPlate: string;
  company: CompanyBasic;
  avatar?: AttachmentImageThumbnail = new AttachmentImageThumbnail();
}

export class UserRole {
  id: number;
  name: string;
}
