import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TimePickerService {
  timeChangeSubject: Subject<any> = new Subject<any>();

  hour: number;
  minute: number;
  min: number[] = [0, 0];
  max: number[] = [23, 59];

  filterType: string;

  timeChanged(timeArray) {
    this.timeChangeSubject.next(timeArray);
  }
}
