import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/_models/user.model';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
  constructor() {}

  @Input()
  user: User;

  @Input()
  big: boolean;

  @Input()
  attachment;
}
