import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { LoaderService } from "./loader.service";

export class Config {
  id: number;
  key: string;
  value: any;
  priority: number;
  table: string;
  idObject: number;
}

export class ConfigColorTheme {
  primary: string;
  secondary: string;
  tertiary: string;
}

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  constructor(private http: HttpClient, private loaderService: LoaderService) {}

  configs: Config[] = [];

  async loadConfigs(reload: boolean = false) {
    if (this.configs.length === 0 || reload) {
      this.configs = <Config[]>await this.getConfigs()
        .toPromise()
        .catch((err) => {
          this.loaderService.loaderError = err;
        });
    }
  }

  private getConfigs() {
    return this.http.get<Config[]>(`${environment.api}config/`);
  }

  async getConfigValue(value: string): Promise<string> {
    if (this.configs.length === 0) {
      await this.loadConfigs();
    }

    if (this.configs.find((a) => a.key === value)) {
      return this.configs.find((a) => a.key === value).value;
    }
  }


  async getConfigColors(): Promise<ConfigColorTheme> {
    if (this.configs.length === 0) {
      await this.loadConfigs();
    }

    if (this.configs.find((a) => a.key === "color_theme")) {
      return this.configs.find((a) => a.key === "color_theme").value;
    }
  }

  getPublicConfig(key, appName) {
    return this.http.get<Config>(
      `${environment.api}config-public?key=${key}&appName=${appName}`
    );
  }
}
