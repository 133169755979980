import { HttpParams } from "@angular/common/http";

export class Utils {
  public static compareWithFn(o1, o2) {
    return o1 === o2;
  }

  public static GetSubdomain(domain: string) {
    if (domain.includes("my-building")) {
      let subdomena = domain
        .split(".")[0]
        .split(".my-building")[0]
        .replace("https://www.", "")
        .replace("http://www.", "")
        .replace("https://", "")
        .replace("http://.", "")
        .replace("my-building", "");

      if (subdomena.toUpperCase() === "WWW") {
        return "";
      }

      return subdomena.toUpperCase();
    }
  }

  public static sameDay(d1, d2) {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  }

  public static replaceNull(someObj, replaceValue = "***") {
    const replacer = (key, value) =>
      String(value) === "null" || String(value) === "undefined"
        ? replaceValue
        : value;

    return JSON.parse(JSON.stringify(someObj, replacer));
  }

  public static httpParams<T>(httpParams: T) {

    let params = new HttpParams();

    Object.entries(httpParams).forEach(([key, value]) => {
      if (value === undefined || value === null ) return;
      
      params = params.set(key, value.toString());
    });

    return params
  }
  
  /**
   * Sprawdza poprawność TinyMCE
   * @param value zawartość eytora
   * @returns poprawność TinyMCE
   */
  public static checkTiny(value: string): boolean {
    const textarea = document.createElement("textarea"); // użycie textarea pozwala zamienić encje html na zwykłe znaki np.: &nbsp; zamienia się na spację lub &amp; na & itp.
    textarea.innerHTML = value;
    const result: boolean = Boolean(textarea.value.replace(/<[^>]+>/g, '').trim().length); // replace(/<[^>]+>/g, '') usuwa znaczniki html
    textarea.remove();
    return result;
  }

  /**
  * Download data as CSV file
  * @param data data to be downloaded and converted to CSV
  * @param filename name of the file to be downloaded
  * @param headerList array of headers to be displayed in CSV file
  */
  public static async downloadCSVFile(data: any, filename='data', headerList: string[] = []) {
    let csvData = await this.ConvertToCSV(data, headerList);

    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;

    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  /**
  * Convert array of objects to CSV
  * @param objArray array of objects to be converted to CSV
  * @param headerList array of headers to be displayed in CSV file
  */
  public static async ConvertToCSV(objArray: any, headerList: any) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';

    for (let index in headerList) {
      row += headerList[index] + ',';
    }
      
    row = row.slice(0, -1);
    str += row + '\r\n';
      
    for (let i = 0; i < array.length; i++) {
      let line = (i+1)+'';
          
      for (let index in headerList) {
        let head = headerList[index];
        line += ',' + array[i][head];
      }
          
      str += line + '\r\n';
    }
      return str;
  }

  /**
  * Flatten nested objects into a single-level object
  * @param obj object to be flattened
  * @param prefix prefix for nested object properties
  * @returns flattened object
  */
  public static flattenObject(obj: any, prefix = '') {
    let flattenedObject: {[key: string]: any} = {};
    
    for (let key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        // Recursively flatten nested objects
        const nestedFlattenedObject = this.flattenObject(obj[key], prefix + key + '.');
        flattenedObject = {...flattenedObject, ...nestedFlattenedObject};
      } else {
        // Assign non-object properties directly
        flattenedObject[prefix + key] = obj[key];
      }
    }

    return flattenedObject;
  }

}
