import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthenticationService } from '../_services';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

import { NoInternetService } from '../_services/no-internet.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        public internetServ: NoInternetService,
        private authenticationService: AuthenticationService,
        private translateServ: TranslateService,
        private alertController: AlertController,
        private router: Router,
        private modalController: ModalController
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // add authorization header with jwt token if available
      let token = this.authenticationService.getAccessToken();
      this.internetServ.isOnline = window.navigator.onLine;

      if (token) {
        request = this.addToken(request, token);
      }

      return next.handle(request).pipe(catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        } else if (error instanceof HttpErrorResponse && error.status === 403) {
          this.handle403Error(error.status)
        } else {
          return throwError(error);
        }
      }));;
    }    

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.authenticationService.refreshToken().pipe(
                catchError((error) => {
                    console.warn('Refresh token error', error);
                    this.authenticationService.logout();
                    return throwError(error);
                }),
                switchMap((token: any) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(token.token);
                    return next.handle(this.addToken(request, token.token));
                }));
        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(jwt => {
                    return next.handle(this.addToken(request, jwt));
                }));
        }
    }

    private async handle403Error(errorCode: Number) {
        console.warn(this.translateServ.instant("ERROR.HTTP_403_MESSAGE"))
        let errorMessage = this.translateServ.instant("ERROR.HTTP_403");
        const alert = await this.alertController.create({
            subHeader: this.translateServ.instant("ERROR.HTTP_403_MESSAGE"),
            message: errorMessage,
            buttons: [
            {
                text: "OK",
                handler: () => this.router.navigate(["/app/home"]),        
            }
            ],
        });
        this.modalController.dismiss();
        await alert.present();
    }

    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`
            }
        });
    }

}