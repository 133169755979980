import { AttachmentImageThumbnail } from "src/app/_models/image.model";
import { CompanyGroup } from "./group/gorup.model";
export class Company {
  id: number;
  name: string;
  description: string;
  telephone: string;
  // idApp: number;
  status: number;
  file: any;
  whitelist: string;
  provider: boolean;
  email: string;
  meetingPointDirections: string;
  invitationConfirmationType: number;
  visibleInVirtualReception: boolean;
  defaultFloor: number; //for guests
  logo: AttachmentImageThumbnail = new AttachmentImageThumbnail();
  departments: CompanyGroup[];
  /** Flag to enabled / disabled parking cancellation notification */
  parkingNotifications: boolean;
  /** Additional email for automatic parking cancellation notification */
  parkingManagerEmail: string;

  public constructor(init?: Partial<Company>) {
    Object.assign(this, init);
  }
}

export class CompanyBasic {
  id: number;
  name: string;
}
