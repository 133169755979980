import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { AlertController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '.';

@Injectable({
  providedIn: 'root'
})
export class AppVersionService {

  constructor(
    private device: Device,
    private platform: Platform,
    private appVersion: AppVersion,
    private http: HttpClient,
    private translateService: TranslateService,
    private alertCtrl: AlertController,
    private authServ: AuthenticationService
  ) { }

  async isUpdateRequired(): Promise<boolean> {
    return new Promise(async (resolve, rejects) => {
      let platformName = 'OTHER';
      if (this.platform.is("ios")) {
        platformName = "IOS";
      }
      if (this.platform.is("android")) {
        platformName = "ANDROID";
      }

      const appVersion = await this.appVersion.getVersionNumber();

      this.version(platformName, appVersion)
        .subscribe((res: { isUpdateRequired: boolean }) => {
          resolve(res.isUpdateRequired);
        });
    })

  }

  version(platform: string, appVersion: string): Observable<any> {
    const { version, model } = this.device;

    let data = {
      platform: platform,
      version: appVersion,
      platformVersion: version,
      deviceModel: model,
      idUser: this.authServ.currentUserValue.id
    };

    return this.http.post(`${environment.api}versions`, data);
  }


  async updateRequired() {
    await this.showAlertUpdate();
  }

  async showAlertUpdate() {
    await this.alertCtrl
      .create({
        header: this.translateService.instant("APP_UPDATE_HEADER"),
        subHeader: this.translateService.instant("APP_UPDATE_REQUIRED"),
        buttons: [
          {
            text: this.translateService.instant("APP_UPDATE_OK"),
            role: "ok",
            handler: () => {
              if (this.platform.is("android")) {
                window.open(
                  "https://play.google.com/store/apps/details?id=it.spacetech.it.mybuilding"
                );
              }
              if (this.platform.is("ios")) {
                window.open(
                  "itms://apps.apple.com/pl/app/id1525713450"
                );
              }
            },
          },
        ],
      })
      .then((alertEl) => {
        alertEl.present();
        return alertEl.onDidDismiss();
      });
  }
}
