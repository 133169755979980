import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from "@ngx-translate/core";
import { SharedDirectivesModule } from "src/app/_directives/shared-directives.module";
import { NoResultComponent } from "./no-result.component";

@NgModule({
  declarations: [NoResultComponent],
  imports: [
    CommonModule,
    TranslateModule,
    IonicModule,
    SharedDirectivesModule
  ],
  exports: [NoResultComponent],
})
export class NoResultModule { }
