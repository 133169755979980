import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { LoadingComponent } from "../app/_component/loading/loading.component";

import { createAnimation, Animation } from "@ionic/core";

export function ModalEnterAnimation(rootElement: HTMLElement): Animation {
  return createAnimation();
}

export function ModalLeaveAnimation(rootElement: HTMLElement): Animation {
  const wrapperAnimation = createAnimation()
    .addElement(rootElement.querySelector(".modal-wrapper")!)
    .fromTo("opacity", 1, 0);

  return createAnimation()
    .addElement(rootElement)
    .easing("ease-in")
    .duration(400)
    .beforeAddClass("show-modal")
    .addAnimation([wrapperAnimation]);
}

@Injectable()
export class LoaderService {
  constructor(private modalCtrl: ModalController) {}

  public loaderError: any = null;
  public open: boolean = false;

  async presentLoading(animation: boolean = false) {
    if (this.open) {
      return;
    }

    this.open = true;

    const modal = await this.modalCtrl.create({
      component: LoadingComponent,
      animated: true,
      cssClass: "loading-modal",
      enterAnimation: ModalEnterAnimation,
      leaveAnimation: ModalLeaveAnimation,
      showBackdrop: false,
      componentProps: {
        loadErrorState: () => {
          return this.loaderError;
        },
      },
    });

    modal.onWillDismiss().then(() => {
      this.open = false;
    });

    await modal.present();
  }

  async hideLoading() {
    if (!this.open) {
      return;
    }

    return await this.modalCtrl.dismiss();
  }
}
