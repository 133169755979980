import { environment } from "../../environments/environment";

export function log (message: string, ...data: any[]) {
  if (environment.production) return;
  console.log(
    ` %c${String.fromCodePoint(0x2139)} ${'From app'}`,
    "background: #0095ff; color: #fff; padding: 2px 4px; border-radius: 3px",
    message,
    ...data,
  );
}

export function warn(message: string, ...data: any[]) {
  if (environment.production) return;
  console.warn(
    `%c${String.fromCodePoint(0x26a0)} ${'From app'}`,
    "background: #fba703; color: #fef5c8; padding: 2px 4px; border-radius: 3px",
    message,
    ...data,
  );
}

export function error(message: string, ...data: any[]) {
  if (environment.production) return;
  console.error(
    `%c${String.fromCodePoint(0x26d4)} ${'From app'}`,
    "background: #EF7676; color: #290000; padding: 2px 4px; border-radius: 3px",
    message,
    ...data,
  );
}