import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ThisPlatform } from "src/app/_services/platform.service";
import { SearchBarButton } from "./search-bar-button";

@Component({
  selector: "app-search-bar",
  templateUrl: "./search-bar.component.html",
  styleUrls: ["./search-bar.component.scss"],
})
export class SearchBarComponent implements OnInit {
  constructor(public p: ThisPlatform) {}

  @Input()
  permissionEdit: boolean;

  @Input()
  addLink: string;

  @Input()
  buttonAddLabel: string;

  @Input()
  onAddClickEvent: boolean = false;

  @Input()
  buttonAddIcon: string = "add";

  @Input()
  buttons: SearchBarButton[] = [];

  @Input()
  gridClass: string = "";

  @Input()
  containerWidthClass: string ="";

  @Input()
  ngModel;

  searchBarSearchDelay = 200;
  lastSearchRef = null;
  showCallendar: boolean = false;

  dateFrom: any;
  dateTo: any;

  @Output()
  searchEvent: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  addClickEvent: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit() {}

  search(data) {
    let searchRef = {
      overload: false,
    };

    if (this.lastSearchRef) {
      this.lastSearchRef.overload = true;
    }

    this.lastSearchRef = searchRef;

    window.setTimeout(() => {
      if (searchRef.overload) {
        return;
      }

      this.searchEvent.emit(data);
    }, this.searchBarSearchDelay);
  }

  onAddClick() {
    this.addClickEvent.emit();
  }
}
