import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { EditBookingComponent } from '../app/booking/edit-booking/edit-booking.component';
import { DebugService } from '../debug/debug.service';
import { User } from '../_models/user.model';
import { AuthenticationService } from './authentication.service';
import { BookingService } from './booking.service';
import OneSignal from 'plugins/onesignal-cordova-plugin/dist';
import { OpenedEvent } from 'plugins/onesignal-cordova-plugin/dist/models/NotificationOpened';
import { BookingType } from '../_models/booking.model';
import { globalHelper } from '../_helpers/global';
import { BookingAction } from '../_models/booking-action.enum';

const { appId, googleProjectNumber } = environment.onesignal;
const url = environment.api;

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  constructor(
    private authService: AuthenticationService,
    private debugService: DebugService,
    private alertCtrl: AlertController,
    private router: Router,
    private bookingService: BookingService,
    private modalCtrl: ModalController,
    private http: HttpClient,
    private translateServ: TranslateService
  ) {}

  public init() {
    // Empty init
    // REMOVED - on app start handleNotificationOpened() does not exec
    // this.oneSignal.startInit(appId, googleProjectNumber);
    // this.oneSignal.endInit();

    // if (this.platform.is("cordova")) {
    this.authService.currentUser.subscribe((user) => {
      if (user) {
        try {
          this.initPushNotification(user);
        } catch (error) {
          console.log(error);
        }
      } else {
        // this.cancelSubscription();
      }
    });
    // }
  }

  private initPushNotification(user: User) {

    const { idBuilding, idCompany, id: idUser, idApp } = user;
    OneSignal.setAppId(appId);
    // OneSignal.disablePush(!user.pushAccepted);

    if(user.pushAccepted) {
      if (idUser) OneSignal.sendTag('user', idUser.toString());
      // if (idCompany) OneSignal.sendTag('company', idCompany.toString());
      // if (idBuilding) OneSignal.sendTag('building', idBuilding.toString());
      if (idApp) OneSignal.sendTag('app', idApp.toString());
    } else {
      this.cancelSubscription();
    };

    OneSignal.setNotificationOpenedHandler((data: OpenedEvent) => {
      this.handleNotificationOpened(data);
    });

    OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
      console.log("User accepted notifications: " + accepted);
    });

  }

  /**
   * Cancels push subscription
   */
  cancelSubscription() {
    this.debugService.debug('Cancel subscription');
    OneSignal.setAppId(appId);
    // OneSignal.disablePush(true);
    OneSignal.deleteTags(['app','user', 'company', 'building']);
  }

  /**
   * Temporary solution for tests
   */
  async showAlert(title: string, msg: string) {
    const alert = await this.alertCtrl.create({
      header: title,
      message: msg,
      buttons: [
        {
          text: 'Ok',
        },
      ],
    });
    await alert.present();
  }

  private handleNotificationOpened(data) {
    if (!data.notification.additionalData) return;

    const { idPush } = data.notification.additionalData as {
      action: RecivedPushAction;
      object: RecivedPushObject;
      id: string;
      idPush: string;
    };

    if (idPush) {
      this.pushVerification(+idPush).subscribe(
        (res) => {
          if (res.status > 200) return;

          this.handlePushLogic(data);
        },
        (error) => {
          this.showAlert(
            this.translateServ.instant('PUSH.EXPIRED.HEADER'),
            this.translateServ.instant('PUSH.EXPIRED.DESCRIPTION')
          );
        }
      );
    } else {
      this.handlePushLogic(data);
    }
  }

  // Otwiera okienka dialogowe, przyjmuje komponent(jego nazwę) za argument
  private async openDialog(componentRef: any) {
    const modal = await this.modalCtrl.create({
      component: componentRef,
      animated: true,
      cssClass: 'add-edit-modal',
      componentProps: {
        fromPush: true,
      },
    });
    // modal.dismiss(() => {
      // this.subscribeEvents();
    // });
    modal.present();
    
  }

  openBookingDialog(idBooking: number) {
    this.bookingService.getBooking(idBooking).subscribe((response) => {
      this.bookingService.booking = response;
      switch(response.type) {
        case BookingType.Room: 
          this.openDialog(EditBookingComponent);
          break;
        case BookingType.Parking:
          this.router.navigate([`/app/parking`]);
          break;
        case BookingType.Desk:
          this.router.navigate([`/app/desk`]);
          break;
        default:
          this.router.navigate([`/app/home`])
          break; 
      }
    });
  }

  handlePushLogic(data: OpenedEvent) {
    const { action, object, id, idPush } = data.notification
    .additionalData as {
      action: RecivedPushAction;
      object: RecivedPushObject;
      id: string;
      idPush: string;
    };

    let showAlert = false;
    switch (action) {
      case RecivedPushAction.NAVIGATE:
        switch (object) {
          case RecivedPushObject.Report:
            this.router.navigate([`/app/report/edit-report/${id}`]);
            break;
          case RecivedPushObject.SpaceAssignment:
            showAlert = false;
            this.router.navigateByUrl(`/app/parking?confirm=${id},${idPush}`);
            break;
          case RecivedPushObject.Community:
            this.router.navigate([`/app/community/detail-community/${id}`]);
            break;
          case RecivedPushObject.IntegrationTrip:
            this.router.navigate([`/app/integration-trip/detail-community/${id}`]);
            break;
          case RecivedPushObject.Rental:
            this.router.navigate([`/app/rental/edit/${id}`]);
            break;
          case RecivedPushObject.Booking:
            // TODO navigate to booking confirmation view (eidt - booking)
            // this.router.navigateByUrl(`/booking`)
            showAlert = false;
            this.bookingConfirm(+id, data.notification.body);
            break;
          case RecivedPushObject.GoodPracticesList:
            this.router.navigate([`/app/practices-list/detail-community/${id}`]);
            break;
        }
        break;
      case RecivedPushAction.CONFIRMATION:
        switch (object) {
          case RecivedPushObject.Booking:
            this.bookingConfirm(+id, data.notification.body);
            break;
        }
    }

    if (showAlert) {
      this.showAlert(
        data.notification.title,
        data.notification.body
      );
    }
  }

  pushConfirmation(id: number, status: 'confirmed') {
    return this.http.patch(`${url}push-log/${id}`, { status: status });
  }

  pushVerification(idPush: number) {
    return this.http.get(`${url}push-log/${idPush}`, { observe: 'response' });
  }

  async bookingConfirm(id: number, message: string) {
    let confirm = await globalHelper.Alert(
      this.alertCtrl,
      this.translateServ,
      message
    );
  
    if (confirm) {
      this.bookingService
        .confirmBooking(id, BookingAction.confirmBooking)
        .subscribe(
          (res) => {
            this.modalCtrl.dismiss();
            this.bookingService.reloadBooking();
          },
          (error) => {
            console.log("Somethins goes wrond: ", error);
          }
        );
    }
  }
}

enum RecivedPushAction {
  NAVIGATE = 'NAVIGATE',
  CONFIRMATION = 'CONFIRMATION',
}

enum RecivedPushObject {
  Report = 'report',
  Booking = 'booking',
  SpaceAssignment = 'space_assignment',
  Community = 'community',
  IntegrationTrip = 'crete', 
  Rental = 'rental',
  GoodPracticesList = 'good_practices_list'
}
