/**
 * Removes preload loading screen
 */
export const removeInitScreen = () => {
  if (!document.getElementById("app-loader").classList.contains("show")) {
    return;
  }

  document.getElementById("app-loader").classList.add("dismissing");
  setTimeout(() => {
    document.getElementById("app-loader").classList.remove("show");
    document.getElementById("app-loader").classList.remove("dismissing");
  }, 800);
};
