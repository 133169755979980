import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AutoCompleteComponent } from './auto-complete.component';

@NgModule({

    imports: [
      CommonModule,
      ReactiveFormsModule,
      FormsModule,
      IonicModule,
      TranslateModule,
    ],
  
    declarations: [AutoCompleteComponent],
    exports: [AutoCompleteComponent]
  
  
  })
  export class AutoCompleteModule { }