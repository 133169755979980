<ion-app class="main-container">
  <ion-split-pane contentId="main" *ngIf="internetServ.isOnline && setColorTheme">
    <ion-menu side="start" contentId="main">
      <ion-menu-toggle autoHide="false">
        <!-- <ion-header> -->
        <ion-toolbar
          color="secondary"
          routerLink="/app/home"
          *ngIf="!location.path().includes('auth')"
          class="app__toolbar"
        >
          <ion-item
            color="secondary"
            lines="none"
            slot="start"
            class="app__logo-container"
          >
            <ion-icon
              class="app__logo"
              src="../assets/images/app_logo_white.svg"
            ></ion-icon>
          </ion-item>
        </ion-toolbar>
        <!-- </ion-header> -->
      </ion-menu-toggle>
      <ion-content scrollbar="dark" *ngIf="!location.path().includes('auth')">
        <ion-grid class="ion-no-padding">
          <ion-row>
            <ion-col *ngIf="pages?.length > 0">
              <ion-list *ngIf="userStorage && pages?.length > 0">
                <div class="menu-items">
                  <ion-menu-toggle autoHide="false">
                    <ion-item
                      replaceUrl="true"
                      [routerLink]="
                        item.disable
                          ? '/app/disabled/' + item.permission.split('.')[0]
                          : item.url
                      "
                      routerLinkActive="active-link"
                      lines="none"
                      *ngFor="let item of pages"
                      [ngClass]="{ 'app__menu-item-disabled': item.disable }"
                    >
                      <ion-icon
                        class="app__menu-icon"
                        [name]="item.icon ? item.icon : ''"
                        [src]="item.iconSrc ? item.iconSrc : ''"
                        slot="start"
                      >
                      </ion-icon>
                      <ion-label>{{ item.titile | translate }}</ion-label>
                    </ion-item>
                  </ion-menu-toggle>
                </div>
              </ion-list>
              <ion-list>
                <ion-menu-toggle autoHide="false">
                  <ion-item
                    routerLink="/legal/privacy-policy"
                    routerLinkActive="active-link"
                    lines="none"
                  >
                    <ion-label class="app__bottom-menu-item">
                      {{ "LEGAL.PRIVACY_POLICY" | translate }}
                    </ion-label>
                  </ion-item>
                  <ion-item
                    routerLink="/legal/application-rules"
                    routerLinkActive="active-link"
                    lines="none"
                  >
                    <ion-label class="app__bottom-menu-item">
                      {{ "LEGAL.APPLICATION_RULES" | translate }}
                    </ion-label>
                  </ion-item>
                  <ion-item
                    routerLink="/app/first-run"
                    routerLinkActive="active-link"
                    lines="none"
                  >
                    <ion-label class="app__bottom-menu-item">
                      {{ "TUTORIAL" | translate }}
                    </ion-label>
                  </ion-item>
                </ion-menu-toggle>

                <div class="app__separator"></div>

                <ion-item lines="none">
                  <ion-label class="app__footer">
                    © {{ currentYear }} by space-tech.it v.{{ currentVersion }} <br />
                    All right reserved.
                  </ion-label>
                </ion-item>
              </ion-list>
            </ion-col>
            <ion-col *ngIf="pages?.length == null">
              <app-loading-sti class="margin-auto"></app-loading-sti>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-content>
    </ion-menu>

    <ion-router-outlet 
      *ngIf="internetServ.isOnline" 
      id="main" 
      [animation]="baseAnimation"
    ></ion-router-outlet>
  </ion-split-pane>
  <app-no-internet-connection class="app__external-element"
    *ngIf="!internetServ.isOnline"
  ></app-no-internet-connection>
  <div class="app__external-element" *ngIf="authService.logoutInProgress">
    <h1>
      {{ "LOGGING_OUT" | translate }}
    </h1>
    <app-loading-sti></app-loading-sti>
  </div>
  <div class="app__external-element" *ngIf="!setColorTheme">
    <app-loading-sti></app-loading-sti>
  </div>
</ion-app>

<app-update-overlay></app-update-overlay>
