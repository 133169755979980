<ion-item class="input header-title" lines="none">
  <ion-icon *ngIf="hideBackButton" slot="start" name="add"></ion-icon>
  <ion-back-button
    [hidden]="hideBackButton"
    *ngIf="p.renderMobile && !alwaysDesktopButton"
    class="inheader"
    defaultHref=""
    (click)="back()"
    slot="start"
  ></ion-back-button>
  <ion-text>{{ header }}</ion-text>
  <ion-back-button
    *ngIf="!p.renderMobile || alwaysDesktopButton"
    class="inheader"
    defaultHref=""
    (click)="back()"
    slot="end"
    icon="close"
  ></ion-back-button>
</ion-item>
