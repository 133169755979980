import {
  AlertController,
  LoadingController,
  ModalController,
  PopoverController,
} from "@ionic/angular";
import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { BookingService } from "../../../_services/booking.service";
import { BookingModel, BookingStatus, EditBookingConfig } from "../../../_models/booking.model";
import { customNgxDate } from "src/app/_helpers/customNgxDate";
import { timePickerCustomTheme } from "../../../_helpers/timePickerTheme";
import { UserService } from "src/app/_services";
import { ErrorService } from "src/app/_services/error.service";
import { ThisPlatform } from "src/app/_services/platform.service";
import { globalHelper } from "src/app/_helpers/global";
import { TranslateService } from "@ngx-translate/core";
import { addMinutes } from "date-fns";
import { DateTimeAdapter } from "@danielmoncada/angular-datetime-picker";
import { BookingAction } from "src/app/_models/booking-action.enum";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DictionaryService } from "src/app/dictionary";
import { Animations } from "src/app/_helpers/animations";
import { SelectModalMultipleUserWithAvatarComponent } from "../../_component/select-modal-multiple-user-with-avatar/select-modal-multiple-user-with-avatar.component";
import { SpaceType } from "src/app/_types/space-type";

@Component({
  templateUrl: "./edit-booking.component.html",
  styleUrls: ["./edit-booking.component.scss"],
  animations: [Animations.animeHeight, Animations.animeRotate],
})
export class EditBookingComponent implements OnInit, OnDestroy {
  booking: BookingModel;
  // to samo co w add-booking
  dateStart: Date;
  bookingTimeStart: string;
  dateEnd: Date;
  bookingTimeEnd: string;
  timePickerCustomTheme = timePickerCustomTheme;
  description: string;
  subject: string;
  bookingInProgress: boolean;
  editPermission = false;
  loadFutureBookings = false;
  pastBooking: boolean;
  disableInput: boolean = false;

  date: any;

  saving = false;

  isChecked = false;
  isError = false;
  hideParticipants = true;

  public startTimeLaterThanEndTime = false;
  public startTimeEqualToEndTime = false;
  public overlappingDatesError: boolean = false;
  public outOfAvailableTimeRange: boolean = false;
  public toShortBookingDuration: boolean = false;

  form: FormGroup;

  @Input()
  roomTimeFrom: string; // minimum timepickera startu
  @Input()
  roomTimeTo: string; // maksimum  timepickerow

  @Input()
  fromPush: boolean = false;

  @Input()
  type: SpaceType;

  @Input()
  config: EditBookingConfig = new EditBookingConfig();

  addBookingModalOpen: boolean = false;

  confirmBookingInFullRangePermission: boolean = false;

  constructor(
    public bookingServ: BookingService,
    private modalCtrl: ModalController,
    private userServ: UserService,
    private error: ErrorService,
    public p: ThisPlatform,
    private alertController: AlertController,
    private translateService: TranslateService,
    private loadingCtrl: LoadingController,
    public popoverController: PopoverController,
    private dateTimeAdapter: DateTimeAdapter<any>,
    public dictionaryServ: DictionaryService,
  ) {}

  async ngOnInit() {
    this.dateTimeAdapter.setLocale(this.userServ.getDefaultLanguage());

    this.booking = this.bookingServ.booking;
    this.dateStart = new Date(this.booking.startTime);
    this.bookingTimeStart = customNgxDate.getTime(this.dateStart);
    this.dateEnd = new Date(this.booking.endTime);
    this.bookingTimeEnd = customNgxDate.getTime(this.dateEnd);
    this.subject = this.booking.title;
    this.description = this.booking.description;

    this.booking.participantsFromApp = this.booking.participants.map( el => { return el.id })

    if (
      this.dateStart.getTime() > new Date().getTime() ||
      this.dateEnd.getTime() < new Date().getTime()
    ) {
      this.bookingInProgress = false;
    } else {
      this.bookingInProgress = true;
    }

    if (this.dateEnd.getTime() < new Date().getTime()) {
      this.pastBooking = true;
    } else {
      this.pastBooking = false;
    }
    
    if(this.type === SpaceType.Room) {
      if (!this.roomTimeFrom) {
        this.roomTimeFrom = "8:00 am";
      }
      if (!this.roomTimeTo) {
        this.roomTimeTo = "7:00 pm";
      }
    };

    this.checkPermission();
    this.disableInput = this.pastBooking || !this.editPermission;

    this.initForm()
  }

  checkPermission() {
    if (this.booking.idUserCreated === this.userServ.userStorage.id) {
      this.editPermission = true;
    } else if (this.booking.idUserOwner === this.userServ.userStorage.id) {
      this.editPermission = true;
    } else {
      this.editPermission = this.userServ.checkRoles([
        "booking.edit.allOwners",
      ]);
    }

    this.confirmBookingInFullRangePermission = this.userServ.hasRoles(['booking.confirm-in-full-time-range']);
  }
  dateChange(date) {
    this.dateStart = date;
    this.dateEnd = date;

    this.booking.startTime = customNgxDate.stringToDateConverter(
      this.dateStart,
      this.bookingTimeStart
    );
    this.booking.endTime = customNgxDate.stringToDateConverter(
      this.dateStart,
      this.bookingTimeEnd
    );
  }

  updateBookingTime(value, startOrEnd: string = "start" || "end") {
    if (startOrEnd == "start") {
      this.bookingTimeStart = value.getHours() + ":" + value.getMinutes();
    } else if (startOrEnd == "end") {
      this.bookingTimeEnd = value.getHours() + ":" + value.getMinutes();
    }
  }

  save() {
    this.booking.title = this.form.controls.subject.value;
    if(!this.disableInput) {
      this.booking.startTime = customNgxDate.stringToDateConverter(
        this.dateStart,
        this.bookingTimeStart
      );
      this.booking.endTime = customNgxDate.stringToDateConverter(
        this.dateEnd,
        this.bookingTimeEnd
      );
      this.booking.description = this.description;
  
      if (this.booking.startTime > this.booking.endTime) {
        this.startTimeLaterThanEndTime = true;
        return;
      } else {
        this.startTimeLaterThanEndTime = false;
      }
  
      if (this.booking.startTime.getTime() == this.booking.endTime.getTime()) {
        this.startTimeEqualToEndTime = true;
        return;
      } else {
        this.startTimeEqualToEndTime = false;
      }
  
      let hourRangeFrom = Date.parse("01/01/2010 " + this.roomTimeFrom);
      let hourRangeTo = Date.parse("01/01/2010 " + this.roomTimeTo);
      let hourEventStart = Date.parse("01/01/2010 " + this.bookingTimeStart);
      let hourEventEnd = Date.parse("01/01/2010 " + this.bookingTimeEnd);
  
      if (hourEventStart < hourRangeFrom || hourEventEnd > hourRangeTo) {
        this.outOfAvailableTimeRange = true;
        return;
      } else {
        this.outOfAvailableTimeRange = false;
      }
    }
    this.saving = true;
    this.bookingServ.updateBooking(this.booking).subscribe(
      () => {
        this.saving = false;
        return this.close();
      },
      (error) => {
        if (error.error.statusCode === 400) {
          if (
            error.error.message ===
            "booking duration cannot be short than 15 minutes"
          ) {
            this.toShortBookingDuration = true;
          } else {
            this.overlappingDatesError = true;
          }
        } else {
          this.error.showPopupAlert('BOOKING.' + error.error.code);
        }
        this.saving = false;
      }
    );
  }

  async delete() {
    let confirm = await globalHelper.Alert(
      this.alertController,
      this.translateService
    );

    if (confirm) {
      this.bookingServ.deleteBooking(this.booking.id).subscribe(() => {
        this.close();
      });
    }
  }

  async finish() {
    let confirm = await globalHelper.Alert(
      this.alertController,
      this.translateService,
      this.translateService.instant("SPACE.FINISH_CONFIRMATION")
    );

    if (confirm) {
      this.loadingCtrl
        .create({
          keyboardClose: true,
          message: this.translateService.instant("SPACE.FINISHING"),
        })
        .then((loadingEl) => {
          loadingEl.present();

          this.bookingServ
            .patchBooking(this.booking, BookingAction.endBooking)
            .subscribe(() => {
              this.close();
              loadingEl.dismiss();
            });
        });
    }
  }

  close() {
    this.bookingServ.reloadBooking();
    this.modalCtrl.dismiss();
  }

  ngOnDestroy() {
    if (document.querySelector("ngx-material-timepicker-container")) {
      document.querySelector("ngx-material-timepicker-container").remove();
    }
  }

  isAllowedToConfirm() {
    if( this.confirmBookingInFullRangePermission ) {
      return true;
    } else {
      // TODO move to global function
      let now = new Date();
      let isTheRightTime =
        now > addMinutes(new Date(this.booking.startTime), -20) &&
        now < addMinutes(new Date(this.booking.startTime), 5);
      let userId = this.userServ.userStorage.id;

      return (
        isTheRightTime &&
        (userId == this.booking.idUserCreated ||
          userId == this.booking.idUserOwner)
      );
    }
  }

  bookingConfirmed() {
    return this.booking.status == BookingStatus.Confirmed;
  }

  async confirm() {
    let confirm = await globalHelper.Alert(
      this.alertController,
      this.translateService,
      this.translateService.instant("BOOKING.CONFIRM_BOOKING")
    );

    if (confirm && this.isAllowedToConfirm()) {
      this.bookingServ
        .confirmBooking(this.booking.id, BookingAction.confirmBooking)
        .subscribe(
          (res) => {
            this.modalCtrl.dismiss();
            this.close();
          },
          (error) => {
            console.log("Somethins goes wrond: ", error);
          }
        );
    }
  }

  async onShowPopover(event: Event) {

    const popover = await globalHelper.Popover(
      this.popoverController,
      event,
      [],
      "user-assignment-view-share__popover",
      "BOOKING.CONFIRM_BOOKING_INFO"
    );
  }

  initForm() {
    this.form = new FormGroup({
      name: new FormControl(this.booking.bookingSpaces[0].space.name, {
        updateOn: "blur",
        validators: [Validators.maxLength(100)],
      }),
      subject: new FormControl(this.subject, {
        updateOn: "blur",
        validators: [Validators.maxLength(100)],
      }),
    })
  }

  async addUser() {
    this.addBookingModalOpen = true;
    const modal = await this.modalCtrl.create({
      component: SelectModalMultipleUserWithAvatarComponent,
      componentProps: {
        headerLabel: this.booking.participants.length > 0 ? "BOOKING.EDIT_USERS" : "BOOKING.ADD_USERS",
        removeCurrentUserFromList: (this.booking.idUserOwner == this.userServ.userStorage.id),
        currentUserList: this.booking.participants.length > 0 ? this.booking.participants : [],
        buttonLabel: this.booking.participants.length > 0 ? "BOOKING.CONFIRM" : "BOOKING.ADD_USERS",
      },
      animated: true,
      cssClass: "add-edit-modal",
    });

    modal.present();
    modal.onDidDismiss().then((data) => {
      this.addBookingModalOpen = false;

      if (data.role !== "backdrop") {
        this.booking.participants = data.data;
        this.booking.participantsFromApp = data.data.map(user => user.id);
      }
    });
  }

  onRemoveUser(user) {
    this.booking.participants = this.booking.participants.filter((el) => el.id !== user.id);
    this.booking.participantsFromApp = this.booking.participantsFromApp.filter((el) => el !== user.id);
  }

  isUserExist(checkID) {
    const isFound = this.booking.participants.some(element => {
      if (element.id === checkID) {
        return true;
      }
      return false;
    });
    return isFound
  }

  toggleParticipants() {
    this.hideParticipants = !this.hideParticipants;
  }
}
