import { Component, OnInit, Input, ElementRef } from "@angular/core";

@Component({
  selector: "app-status",
  templateUrl: "./status.component.html",
  styleUrls: ["./status.component.scss"],
})
export class StatusComponent implements OnInit {
  @Input()
  directionColumn: boolean;

  @Input() set color(value: string) {
    this.elementRef.nativeElement.style.setProperty("--color", value);
  }

  @Input() name: string;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {}
}
