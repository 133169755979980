import { Location } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { MenuController, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbService } from 'xng-breadcrumb';
import { DictionaryService } from './dictionary/dictionary.service';
import { HeaderModel } from './_component/header/header.model';
import { removeInitScreen } from './_helpers/appLoading';
import { Modules } from './_helpers/module';
import { User } from './_models/user.model';
import { AuthenticationService, UserService } from './_services';
import { ConfigColorTheme, ConfigService } from './_services/config.service';
import { NoInternetService } from './_services/no-internet.service';
import { ThisPlatform } from './_services/platform.service';
import { PushNotificationService } from './_services/push-notification.service';
import { TranslateConfigService } from './_services/translate-config.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  pages;
  pagesAll;
  userStorage: User;

  headerInit: HeaderModel = { title: 'REPORT.REPORTS' };
  toastNetwork;
  alertShow = false;
  cnt = 0;
  loading: boolean = false;
  currentYear = new Date().getFullYear();
  currentVersion: string = '';
  baseAnimation = null;
  disabledIdArray = [];
  setColorTheme: boolean = true;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    public authService: AuthenticationService,
    public userServ: UserService,
    private translateConfigService: TranslateConfigService,
    private translateService: TranslateService,
    public menu: MenuController,
    public p: ThisPlatform,
    private dictionaryService: DictionaryService,
    public location: Location,
    private configServ: ConfigService,
    private nav: NavController,
    private pushNotificationService: PushNotificationService,
    private device: Device,
    private breadcrumbService: BreadcrumbService, // nie usuwac
    public internetServ: NoInternetService
  ) { }

  async ngOnInit() {
    this.currentVersion = environment.version;
    
    this.authService.currentUser.subscribe((user) => {
      this.userStorage = user;
      this.initializeApp();
    });

    await this.platform.ready();

    if (this.platform.is('cordova')) {
      this.pushNotificationService.init();
    }

    this.splashScreen.hide();
    removeInitScreen();
    this.platform.backButton.subscribeWithPriority(1, () => {
      if (
        this.location.path().includes('auth') ||
        this.location.path().includes('home')
      ) {
        navigator['app'].exitApp();
      } else {
        this.nav.pop();
      }
    });
  }

  async initializeApp() {
    //Prevent app crash when network not available

    try {
      this.setColorTheme = false;
      await this.refreshUserModules();
      if (this.authService.getAccessToken()) {
        this.onSetColorsApp();
        this.userServ.refreshCurrentUser();
      } else {
        this.setColorTheme = true;
      }

      this.pagesAll = Modules ? Modules.filter((p) => p.module >= 0) : null;

      if (this.userStorage === null) {
        this.translateConfigService.getDefaultLanguage();
        return;
      }

      if (this.userStorage.language === null) {
        this.userStorage.language = this.translateConfigService.getDefaultLanguage();
        this.translateConfigService.setLanguage(
          this.translateConfigService.getDefaultLanguage()
        );
      } else {
        this.translateConfigService.setLanguage(
          this.userStorage && this.userStorage.language
            ? this.userStorage.language
            : this.translateService.getBrowserLang()
        );
        await this.configServ.loadConfigs();
      }

      this.dictionaryService.refreshDictionary();
      await this.userServ.getPermision(true);
      if (this.userStorage.availableModules.length > 0) {
        this.pages = this.pagesAll.filter((array) =>
          this.userStorage.availableModules.some((filter) => {
            return (
              (array.module === filter.idModule || array.module === 0) &&
              this.userServ.hasRoles([array.permission]) === true
            );
          })
        );
      } else {
        this.pages = this.pagesAll.filter(
          (array) =>
            array.module === 0 &&
            this.userServ.hasRoles([array.permission]) === true
        );
      }

      await this.getArrayOfDisabledModules();

      this.pages.forEach((page) => {
        if (this.disabledIdArray.includes(page.module)) {
          page['disable'] = true;
        }
      });

      this.authService.userModules = this.pages.filter(el => {
        if (!el.disable) return el
      })

      if (this.userStorage === null) {
        this.userStorage = new User();
      }
    } catch (error) {
      console.warn(error);
    }
  }

  @HostListener('window:resize', [])
  onResize() {
    this.p.onResize(window.innerWidth);
  }

  @HostListener('window:ionModalDidPresent', []) // zamykanie modala poprzez naciśnięcie tła
  onModalPresent() {
    const modals = document.getElementsByTagName('ion-modal');
    for (const i in modals) {
      modals[i].shadowRoot
        .querySelector('.modal-shadow')
        .setAttribute('style', 'opacity: 1; pointer-events: none;');
    }
  }

  async getArrayOfDisabledModules() {
    let modules = this.userStorage.modules.map((el) => el.idModule);

    this.disabledIdArray = this.userStorage.availableModules.filter(
      (el) => !modules.includes(el.idModule)
    );

    this.disabledIdArray = this.disabledIdArray.map((el) => el.idModule);
  }

  async refreshUserModules() {
    if (this.userStorage) {
      this.authService.getUserModules(this.userStorage).subscribe({
        next: (res) => this.userStorage.modules = res
      });
    };
  }

  async onSetColorsApp() {

    let color = await this.configServ.getConfigColors();

    if(color && this.validateColorsTheme(color)) {
      document.documentElement.style.setProperty('--ion-color-primary', color?.primary);
      document.documentElement.style.setProperty('--ion-color-secondary', color?.secondary);
      document.documentElement.style.setProperty('--ion-color-tertiary', color?.tertiary);
      document.documentElement.style.setProperty('--button-outline--color', color?.tertiary);
    }
    
    this.setColorTheme = true;
  }

  private validateColorsTheme(color: ConfigColorTheme) {
    if( color?.primary && color?.secondary && color?.tertiary) {
      return true;
    } else {
      return false
    }
  }

  ngOnDestroy() {
    this.authService.logoutInProgress = false;
  }
}
