import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { CompanyGroup } from '../app/company/group/gorup.model';
import { Utils } from '../_helpers/utils';
import { User, UserBasic, UserRole } from '../_models/user.model';
import { TranslateConfigService } from '../_services/translate-config.service';
import { AuthenticationService } from './authentication.service';

const url = environment.api;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user: User = new User();
  userBasic: UserBasic = new UserBasic();
  users: UserBasic[] = [];
  userStorage: User = new User();
  permission: BehaviorSubject<string[]> = new BehaviorSubject([]);
  lastSegment = '';
  userGroupDeparments: CompanyGroup[] = [];
  userRoles: UserRole[] = [];

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private translateConfigService: TranslateConfigService
  ) {
    this.authService.currentUser.subscribe((newUser) => {
      this.userStorage = newUser;
    });
  }

  getPermissionSubject() {
    return this.permission.asObservable();
  }

  getAll() {
    return this.http.get<UserBasic[]>(url + 'users');
  }

  async getUserSync(id: number) {
    return await this.http.get<User>(url + 'users/' + id).toPromise();
  }

  getActive(idCompany?: number) {
    let httpParams = new HttpParams();

    httpParams = httpParams.set('active', '1');

    if (idCompany) {
      httpParams = httpParams.set('idCompany', idCompany);
    }

    return this.http.get<UserBasic[]>(url + 'users', {
      params: httpParams,
    });
  }

  getActiveSync(reload: boolean = false, idCompany = 0) {
    if (this.users.length === 0 || reload) {
      return this.getActive(idCompany)
        .toPromise()
        .then((users: UserBasic[]) => {
          this.users = users;
        });
    }
  }

  getById(id: number) {
    return this.http.get(url + 'users/' + id);
  }

  register(user: UserBasic) {
    return this.http.post(url + 'users/register', user);
  }

  createUser(user) {
    return this.http.post(url + 'users', user);
  }

  update(userId: number, formData: any) {
    return this.http.put(url + 'users/' + userId, formData);
  }

  updateUser(user, userId) {
    return this.http.put(url + 'users/' + userId, user);
  }

  delete(id: number) {
    return this.http.delete(url + 'users/' + id);
  }

  checkModule(moduleId: number) {
    return this.userStorage?.modules.some((i) => i.idModule === moduleId);
  }

  getUsersCompany(queryParams: {
    idCompany?: number;
    basicModel?: string;
    active?: string;
    idGroup?: number;
  }) {
    return this.http.get<User[]>(url + 'users', {
      params: Utils.httpParams(queryParams),
    });
  }

  getDefaultLanguage() {
    let currentLang: string;

    if (this.userStorage.language === null) {
      this.userStorage.language = this.translateConfigService.getDefaultLanguage();
      currentLang = this.translateConfigService.getDefaultLanguage();
    } else {
      currentLang =
        this.userStorage && this.userStorage.language
          ? this.userStorage.language
          : 'pl';
    }

    return currentLang;
  }

  private getPermission() {
    return this.http.get(url + 'permission');
  }

  public get permissionValue(): string[] {
    return this.permission.value;
  }

  async getPermision(reaload: boolean = false) {
    if (this.permission.value?.length === 0 || reaload) {
      let res: any = await this.getPermission().toPromise();

      this.permission.next(res);
      this.permission.complete();
    }
  }

  async hasRolesAsync(roles: string[]): Promise<boolean> {
    if (roles.length === 1) {
      if (roles[0] === '') {
        return true;
      }
    }

    if (this.permission.value?.length == 0) {
      await this.permission.toPromise();
    }

    return this.checkRoles(roles);
  }

  checkRole(roleId: number): UserRole {
    return this.userRoles.find((i) => i.id === roleId);
  }

  hasRoles(roles: string[]): boolean {
    if (roles.length === 1) {
      if (roles[0] === '') {
        return true;
      }
    }

    return this.checkRoles(roles);
  }

  checkRoles(roles: string[]) {
    if (this.permission.value) {
      if (this.permission.value.includes('admin')) {
        return true;
      }
      for (const oneRole of roles) {
        if (!this.permission.value.includes(oneRole)) {
          return false;
        }

        return true;
      }
    }
  }

  loadRolesSync() {
    return this.http
      .get(url + 'role')
      .toPromise()
      .then((userRoles: UserRole[]) => {
        this.userRoles = userRoles;
      });
  }

  async permissionEditCompanyAsync(
    companyId: number,
    roles: string[],
    rolesCompany: string[]
  ): Promise<boolean> {
    if (this.permission.value?.length == 0) {
      await this.permission.toPromise();
    }

    if (this.hasRoles(roles)) {
      return true;
    } else if (
      this.hasRoles(rolesCompany) &&
      companyId === this.userStorage.idCompany
    ) {
      return true;
    } else {
      return false;
    }
  }

  permissionEditCompany(
    companyId: number,
    roles: string[],
    rolesCompany: string[]
  ): boolean {
    if (this.hasRoles(roles)) {
      return true;
    } else if (
      this.hasRoles(rolesCompany) &&
      companyId === this.userStorage.idCompany
    ) {
      return true;
    } else {
      return false;
    }
  }

  getShortUserName(str: string) {
    let arr = str.split(' ');

    if (arr.length == 1) {
      return arr[0].length > 0 ? arr[0][0] : '';
    }

    return (
      (arr[0].length > 0 ? arr[0][0] : '') +
      (arr[1].length > 0 ? arr[1][0] : '')
    );
  }

  getUserName(user: User) {
    return user.firstName + (user.lastName ? ' ' + user.lastName : '');
  }

  getUserBuilding(user: User) {
    return user.firstName + (user.lastName ? ' ' + user.lastName : '');
  }

  refreshCurrentUser() {
    if(this.userStorage) {
      this.getById(this.userStorage.id).subscribe({
        next: (_user) => {
          const refreshUser: User = {
            ...this.userStorage, 
            ..._user
          };

          this.userStorage = refreshUser;
          localStorage.setItem("currentUser", JSON.stringify(refreshUser));
        },
        error: (err) => {
          console.log('ERROR', err.error);
        },
      })
    }
  }
}
