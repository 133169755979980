import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { DictionaryValues } from "src/app/dictionary";
import { DictionaryService } from "src/app/dictionary/dictionary.service";
import { ThisPlatform } from "src/app/_services/platform.service";
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: "app-category-modal",
  templateUrl: "./category-modal.page.html",
  styleUrls: ["./category-modal.page.scss"],
})
export class CategoryModalPage implements OnInit {

  name: string;
  dictionaryValue: DictionaryValues;
  errorMessage = '';

  form: FormGroup;
  
  @Input() idDictionary: number;
  @Input() idItem: number;

  constructor(
    public modalCtrl: ModalController,
    public dictionaryServ: DictionaryService,
    public p: ThisPlatform,
    public translate: TranslateService,
  ) {}

  ngOnInit() {
    if (this.idItem) {
      this.name = this.dictionaryServ.dictionaryMarketGroups.dictionaryValues.find(
        (p) => p.id === this.idItem
      ).key;

      this.dictionaryValue = this.dictionaryServ.dictionaryMarketGroups.dictionaryValues.find(
        (p) => p.id === this.idItem
      );
    } else {
      this.name = "";
    }
    this.initForm();
  }

  Save() {
    this.name = this.form.controls.name.value;

    if (this.idItem) {
      this.dictionaryValue.key = this.name;

      this.dictionaryServ
        .editDictionaryValue(
          this.idDictionary,
          this.idItem,
          this.dictionaryValue
        )
        .subscribe((a) => {
          this.modalCtrl.dismiss();
        },
        (error) => {
          this.setErrorMessage(error.error.code);
        });
    } else {
      this.dictionaryServ
        .createDictionaryValue(this.idDictionary, this.name)
        .subscribe((a) => {
          this.modalCtrl.dismiss();
        },
        (error) => {
          this.setErrorMessage(error.error.code);
        });
    }
  }

  back() {
    this.modalCtrl.dismiss();
  }

  setErrorMessage(code: number) {
    switch (code){
      case 0: {
        this.errorMessage = this.translate.instant('MARKET.ERROR.SAME_NAMES');
        break;
      }
      default: {
        this.errorMessage = '';
        break;
      }
    }
  }

  initForm() {
    this.form = new FormGroup({
      name: new FormControl(this.name, {
        updateOn: "blur",
      }),
    })
  }

}
