import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { InputComponent } from './input/input.component';
import { UsersListComponent } from './users-list/users-list.component';
import { FormsModule } from '@angular/forms';
import {MaskitoDirective} from '@maskito/angular';



@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    FormsModule,
    MaskitoDirective
  ],
  declarations: [
    InputComponent,
    CheckboxComponent,
    UsersListComponent,
  ],
  exports: [
    InputComponent,
    CheckboxComponent,
    UsersListComponent,
  ]
})
export class InputsModule { }
