import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UserService } from "src/app/_services";
import { AlertController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { ThisPlatform } from "../../../_services/platform.service";
import { BrowserModule } from "@angular/platform-browser";

@Component({
  selector: "app-loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.scss"],
})
export class LoadingComponent implements OnInit {
  constructor(
    public alertController: AlertController,
    private translate: TranslateService,
    public platform: ThisPlatform
  ) {}

  @Input()
  loadErrorState: () => any = () => {
    return null;
  };

  ngOnInit() {}

  refresh() {
    window.location.reload();
  }

  // Pobieranie nazwy przeglądarki
  public getBrowserName(): string {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf("edge") > -1:
        return "edge";
      case agent.indexOf("opr") > -1 && !!(<any>window).opr:
        return "opera";
      case agent.indexOf("chrome") > -1 && !!(<any>window).chrome:
        return "chrome";
      case agent.indexOf("trident") > -1:
        return "ie";
      case agent.indexOf("firefox") > -1:
        return "firefox";
      case agent.indexOf("safari") > -1:
        return "safari";
      default:
        return "other";
    }
  }
}
