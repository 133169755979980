export enum DictionariesNames {
    /**
     * Report
     */
    REPORT_SYSTEM = 'report.system',
    REPORT_STATUS = 'report.status',
    REPORT_TYPE = 'report.type',
    REPORT_PRIORITY = 'report.priority',

    /**
     * Report task
     */
    REPORT_TASK_STATUS = 'report.task.status',

    /**
     * Market place
     */
    MARKET_ORDER_STATUS = 'market.order.status',
    MARKET_GROUP = 'market.group',
    
    /**
     * User status
     */
    USER_STATUS = 'user.status'
}


