import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { DateTimePickerComponent } from "./date-time-picker.component";
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from "@danielmoncada/angular-datetime-picker";
import { TranslateModule } from "@ngx-translate/core";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxMaterialTimepickerModule,
    ReactiveFormsModule,
  ],
  declarations: [DateTimePickerComponent],
  exports: [DateTimePickerComponent],
})
export class DateTimePickerComponentModule {}
