import { Injectable } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class ErrorService {
  constructor(
    public alertController: AlertController,
    public translateService: TranslateService
  ) {}

  async showPopupAlert(customTranslateKey?) {
    const alert = await this.alertController.create({
      cssClass: "error-service",
      header: this.translateService.instant("ERROR.HEADER"),
      message: customTranslateKey
        ? this.translateService.instant(customTranslateKey)
        : this.translateService.instant("ERROR.MESSAGE"),
      buttons: ["OK"],
    });

    await alert.present();
  }
}
