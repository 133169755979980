<ion-content>
  <ion-grid>
    <ion-row class="no-internet-connection__container">
      <ion-col size="12" class="no-internet-connection__top-column">
        <img src="./../../assets/images/no-internet.png" alt="No internet connection"
          class="no-internet-connection__image" />
      </ion-col>
      <ion-col size="12" class="no-internet-connection__center-column">
        <h2 class="no-internet-connection__subheader">
          {{ 'NO_INTERNET.HEADER' | translate }}
        </h2>
        <p class="no-internet-connection__description">
          {{ 'NO_INTERNET.MESSAGE' | translate }}
        </p>
      </ion-col>
      <ion-col size="12">
        <ion-button color="primary" class="no-internet-connection__refresh-button" (click)="onClickRefreshPage()">
          {{ 'NO_INTERNET.REFRESH_TEXT' | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>