import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { ThisPlatform } from "src/app/_services/platform.service";

@Component({
  selector: "no-result",
  templateUrl: "./no-result.component.html",
  styleUrls: ["./no-result.component.scss"],
})
export class NoResultComponent {
  constructor(public p: ThisPlatform, public router: Router) { }

  @Input() 
  collection: [];

  @Input() 
  text: string;

  @Input() 
  addLink: string;

  @Input()
  addButtonText: string;

  @Input()
  permissionAdd: string;


  isNull(collection: []) {
    if (collection.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  onReroute(link) {
    this.router.navigate([link]);
  }
}
