export enum AppModule {
  Reception = 1,
  Reports = 2,
  Info = 3,
  Market = 4,
  Community = 5,
  Parking = 6,
  Booking = 7,
  IntegrationTrip = 8,
  Desk = 9,
  Rental = 10,
  Other = 0,
  None = -1,
  Test = 98,
  GoodPracticesList = 11,
}

export const Modules = [
  {
    titile: 'MENU.YOUR_INVITATIONS',
    url: '/app/invitation',
    iconSrc: '../assets/icons/menu/virtual-reception.svg',
    module: AppModule.Reception,
    permission: 'invitation.view',
  },
  {
    titile: 'MENU.BOOKING',
    url: '/app/booking',
    iconSrc: '../assets/icons/menu/rezerwacja.svg',
    module: AppModule.Booking,
    permission: 'booking.view',
  },
  {
    titile: 'MENU.REPORTS',
    url: '/app/report',
    icon: 'clipboard-outline',
    module: AppModule.Reports,
    permission: 'reports.view',
  },
  {
    titile: 'MENU.TASKS',
    url: '/app/report-task',
    icon: 'document-text-outline',
    module: AppModule.Reports,
    permission: 'tasks.view',
  },
  {
    titile: 'MENU.PARKING',
    url: '/app/parking',
    iconSrc: '../assets/icons/menu/parking1.svg',
    module: AppModule.Parking,
    permission: 'parking.view',
  },
  {
    titile: 'MENU.DESK',
    url: '/app/desk',
    iconSrc: '../assets/icons/menu/desk.svg',
    module: AppModule.Desk,
    permission: 'desk.view',
  },
  {
    titile: 'MENU.MARKET_PLACE',
    url: '/app/market',
    iconSrc: '../assets/icons/menu/marketplace.svg',
    module: AppModule.Market,
    permission: 'market.view',
  },
  {
    titile: 'COMMUNITY.COMMUNITY',
    url: '/app/community',
    iconSrc: '../assets/icons/menu/community.svg',
    module: AppModule.Community,
    permission: 'community.view',
  },
  {
    titile: 'MENU.INFO',
    url: '/app/info',
    icon: 'information-circle-outline',
    module: AppModule.Info,
    permission: 'info.view',
  },
  {
    titile: 'MENU.INTEGRATION_LOCATION',
    url: '/app/integration-trip',
    icon: 'sunny-outline',
    module: AppModule.IntegrationTrip,
    permission: 'turcjaSNT.view',
  },
  {
    titile: 'MENU.RENTAL',
    url: '/app/rental',
    module: AppModule.Rental,
    permission: 'rental.view',
    iconSrc: '../assets/icons/menu/book-rental.svg',
  },
  {
    titile: 'MENU.PRACTICES_LIST',
    url: '/app/practices-list',
    icon: 'list-outline',
    module: AppModule.GoodPracticesList,
    permission: 'good-practices.view',
  },
  {
    titile: 'MENU.BUILDINGS',
    url: '/app/building',
    icon: 'business-outline',
    module: AppModule.Other,
    permission: 'building.view',
  },
  {
    titile: 'MENU.COMPANIES',
    url: '/app/company',
    icon: 'newspaper-outline',
    module: AppModule.Other,
    permission: 'company.view',
  },
  {
    titile: 'MENU.USERS',
    url: '/app/users',
    icon: 'people-outline',
    module: AppModule.Other,
    permission: 'user.view',
  },
  {
    titile: 'Home',
    url: '/app/home',
    module: AppModule.None,
    permission: '',
  },
  {
    titile: 'settings',
    url: '/app/settings',
    module: AppModule.None,
    permission: 'my.settings',
  },
  {
    titile: 'Administrator',
    url: '/app/administrator',
    iconSrc: '../assets/icons/menu/admin-panel.svg',
    module: AppModule.Other,
    permission: 'administrator.view',
  },

  // {
  //   titile: "debug",
  //   url: "/app/debug",
  //   module: AppModule.Other,
  //   permission: "my.debug",
  // },
];
