<!-- DESKTOP -->
<ng-container *ngIf="!p.renderMobile">
  <input
    [ngClass]="{ cssClass: cssClass }"
    [owlDateTime]="date"
    [owlDateTimeTrigger]="date"
    [ngClass]="pickerType === 'timer' ? 'selecttime' : 'selectdate'"
    readonly
    (click)="onModalOpened($event)"
    [placeholder]="placeholder"
    [min]="min"
    [max]="max"
    [value]="value"
    (dateTimeChange)="dateTimePickerClosed($event)"
  />
  <owl-date-time
    [pickerType]="pickerType"
    #date
    firstDayOfWeek="1"
    [panelClass]="pickerType == 'both' ? 'show-timer' : ''"
    [disabled]="disabled"
  ></owl-date-time>
</ng-container>

<!-- MOBILE -->
<ng-container *ngIf="p.renderMobile">
  <input
    [class]="cssClass"
    *ngIf="pickerType === 'both' || pickerType === 'calendar'"
    [owlDateTime]="date"
    [owlDateTimeTrigger]="date"
    class="selectdate"
    readonly
    (click)="onModalOpened($event)"
    [placeholder]="placeholder"
    [min]="min"
    [max]="max"
    [value]="value"
    (dateTimeChange)="dateTimePickerClosed($event)"
  />
  <owl-date-time
    [pickerType]="pickerType"
    #date
    firstDayOfWeek="1"
    [panelClass]="pickerType == 'both' ? 'show-timer' : ''"
    [disabled]="disabled"
  ></owl-date-time>

  <!-- mobile time picker -->
  <form [formGroup]="formGroup">
    <input
      *ngIf="pickerType === 'both' || pickerType === 'timer'"
      #dateTimePickerTimeSet
      placeholder="{{ 'ADD_INVITATION.SELECT_TIME' | translate }}"
      [type]="pickerType === 'both' ? 'hidden' : 'text'"
      class="selecttime"
      aria-label="24hr format"
      [ngxTimepicker]="time"
      formControlName="time"
      [value]="formatInitialTime()"
      [format]="24"
      [min]="timerMin"
      [max]="timerMax"
      readonly
      [disabled]="disabled"
    />
    <ngx-material-timepicker
      (timeSet)="setDateTimeModal($event)"
      #time
      [enableKeyboardInput]="false"
      editableHintTmpl="false"
      [theme]="timePickerCustomTheme"
      timepickerClass="timepicker-custom"
      [cancelBtnTmpl]="cancelBtn"
    >
    </ngx-material-timepicker>
  </form>
</ng-container>

<ng-template #cancelBtn>
  <button class="timepicker-button">{{ 'CANCEL' | translate }}</button>
</ng-template>
