import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'app/home', pathMatch: 'full' },

  {
    path: '',
    loadChildren: () =>
      import('./public/public-routing.module').then(
        (m) => m.PublicRoutingModule
      ),
  },
  {
    path: 'app',
    loadChildren: () =>
      import('./app/app-routing.module').then((m) => m.AppRoutingModule),
    canActivate: [AuthGuard],
  },
  /**
   * This must by always last!!!!
   */
  {
    path: '**',
    loadChildren: () =>
      import('./page-not-found/page-not-found.module').then(
        (m) => m.PageNotFoundPageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
