import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { HeaderModule } from "../header/header.module";
import { TranslateModule } from "@ngx-translate/core";
import { SharedDirectivesModule } from "src/app/_directives/shared-directives.module";
import { UserSettingsComponentModule } from "../../app/_component/user-settings/user-settings.module";
import { NoInternetConnectionComponent } from "./no-internet-connection.component";

@NgModule({
  declarations: [NoInternetConnectionComponent],
  imports: [
    CommonModule,
    IonicModule,
    HeaderModule,
    TranslateModule,
    SharedDirectivesModule,
    UserSettingsComponentModule,
  ],
  exports: [NoInternetConnectionComponent],
})
export class NoInternetConnectionComponentModule {}