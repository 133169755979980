import { NgModule } from "@angular/core";
import { CommonModule, DecimalPipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { TranslateModule } from "@ngx-translate/core";
import { SharedDirectivesModule } from "src/app/_directives/shared-directives.module";
import { TimePickerComponent } from "./time-picker.component";
import { BrowserModule } from "@angular/platform-browser";

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    SharedDirectivesModule,
  ],
  providers: [DecimalPipe],
  declarations: [TimePickerComponent],
  exports: [TimePickerComponent],
})
export class TimePickerComponentModule {}
