import { PipesModule } from 'src/app/_pipes/pipes.module';
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { EditBookingComponent } from "./edit-booking.component";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from "@danielmoncada/angular-datetime-picker";
import { TranslateModule } from "@ngx-translate/core";
import { ModalHeaderComponentModule } from "../../_component/modal-header/modal-header.module";
import { DateTimePickerComponentModule } from "../../_component/date-time-picker/date-time-picker.module";
import { InputsModule } from 'src/app/_component/inputs/inputs.module';
import { StatusModule } from '../../_component/status/status.module';
import { SharedDirectivesModule } from 'src/app/_directives/shared-directives.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    TranslateModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ModalHeaderComponentModule,
    DateTimePickerComponentModule,
    PipesModule,
    InputsModule,
    StatusModule,
    SharedDirectivesModule
  ],
  declarations: [EditBookingComponent],
  exports: [EditBookingComponent],
})
export class EditBookingComponentModule {}
