<ion-content>
  <ion-item *ngIf="customText" lines="none">
    <ion-label>{{ customText | translate }}</ion-label>
  </ion-item>

  <ng-container *ngIf="buttonList">
    <ion-item
      class="popover__item"
      lines="none"
      button
      (click)="onClickButton(button.eventType)"
      detail="false"
      *ngFor="let button of buttonList"
    >
      <ion-label> {{ button.name | translate }} </ion-label>
      <ion-icon
        *ngIf="!isSrc(button.icon); else fromSrc"
        slot="start"
        class="popover__icon"
        [name]="button.icon"
      ></ion-icon>
      <ng-template #fromSrc>
        <ion-icon
          slot="start"
          [src]="button.icon"
          class="popover__icon"
        ></ion-icon>
      </ng-template>
    </ion-item>
  </ng-container>
  <ng-container *ngIf="legendList">
    <ng-container *ngFor="let legend of legendList">
      <ion-item lines="none" detail="false">
        <ion-label> {{ legend.label | translate }} </ion-label>
        <ion-icon
          slot="start"
          name="square"
          [ngStyle]="{ color: legend.color }"
        ></ion-icon>
      </ion-item>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="equipmentList">
    <ng-container *ngFor="let equipment of equipmentList">
      <ion-item lines="none" detail="false">
        <ion-label> {{ equipment.name | translate }} </ion-label>
        <ion-icon
          slot="start"
          src="../../../../assets/icons/equipment/{{ equipment.iconName }}.svg"
        ></ion-icon>
      </ion-item>
    </ng-container>
  </ng-container>
</ion-content>
